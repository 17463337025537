import React from 'react'
import path from 'path-browserify'
import { formatDistanceToNow } from 'date-fns'
import FirestoreImage from '../components/FirestoreImage'
import { getFunctions, httpsCallable } from 'firebase/functions'

export function getThumbnailPath (storagePath) {
  const thumbsFolder = 'thumbs'
  const suffix = '_200x200'

  const ext = path.extname(storagePath)
  const filename = path.basename(storagePath, ext)
  const dirname = path.dirname(storagePath)

  // if storagePath is '/profiles/myfile.jpg',
  // the thumbs path would be '/profiles/thumbs/myfile_200x200.jpg

  return path.join(dirname, thumbsFolder, `${filename}${suffix}${ext}`)
}

export function createField ({
  dataField,
  text = dataField,
  editable = false,
  hidden = false,
  formatter,
  title,
  isDummyField
}) {
  const disableClick = { onClick: e => e.stopPropagation() }

  return {
    dataField,
    text,
    editable,
    hidden,
    formatter,
    title,
    events: disableClick,
    isDummyField
  }
}

export function relativeTimestampFormatter (cell, row) {
  if (cell) {
    const cellDate = cell.toDate()
    const relativeDate = formatDistanceToNow(cellDate, { addSuffix: true })

    return relativeDate
  } else {
    return '[not set]'
  }
}

export function getImage (cell, row) {
  if (cell) {
    const storagePath = cell.toString()
    return <FirestoreImage storagePath={storagePath} style={{ width: 50 }} />
  } else {
    return <img src='/images/default.png' style={{ height: '50px' }}/>
  }
}

export async function serverCall ({
  functionName,
  functionParams,
  doConfirm = false,
  confirmMessage = 'Are you sure?',
  preSubmit = () => {}
}) {
  if (doConfirm && !window.confirm(confirmMessage)) {
    return {}
  }

  const functions = getFunctions()
  checkAndExecuteFunction(preSubmit)

  const httpsCall = httpsCallable(functions, functionName)
  return httpsCall(functionParams)
}

export function checkAndExecuteFunction (obj) {
  const isFunction = !!(obj && obj.constructor && obj.call && obj.apply)
  if (isFunction) {
    obj()
  }
}
