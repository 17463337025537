const KEY_WATER_COLOR_SETTINGS = 'watercolorSettings'

// between two floats
// The minimum is inclusive and the maximum is exclusive
function getRandomArbitrary (min, max) {
  return Math.random() * (max - min) + min
}

// between two ints
// The minimum is inclusive and the maximum is exclusive
function getRandomInt (min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}

function getRandomWatercolorSettings () {
  const lightColors = !!(getRandomInt(0, 2))
  return {
    colorSize: getRandomInt(50, 501),
    deformations: getRandomInt(1, 6),
    randomSeed: getRandomInt(0, 1000),
    sigma: Math.min(getRandomArbitrary(1.5, 3.1).toFixed(1), 3.0),
    mask: !!(getRandomInt(0, 2)),
    maskCircles: getRandomInt(5, 301),
    maskCircleSize: getRandomInt(1, 301),
    lightColors,
    darkColors: !lightColors,
    blend: getRandomInt(0, 2) ? 'lighten' : 'darken'
  }
}

// gets available, if not availble, gets random (and saves as the setting)
function getWatercolorSettings () {
  let settings = window.localStorage.getItem(KEY_WATER_COLOR_SETTINGS)
  if (!settings) {
    settings = getRandomWatercolorSettings()
    setWatercolorSettings(settings)
  }

  return JSON.parse(settings)
}

function setWatercolorSettings (settings) {
  window.localStorage.setItem(KEY_WATER_COLOR_SETTINGS, JSON.stringify(settings))
}

module.exports = {
  getRandomWatercolorSettings,
  getWatercolorSettings,
  setWatercolorSettings
}
