import React from 'react'

export const EXPANDROW = {
  renderer: row => (
    <pre>{JSON.stringify(
      row,
      null,
      2)
      }</pre>
  ),
  showExpandColumn: true
}

export const COLUMNS = [
  {
    dataField: 'id',
    text: 'id',
    editable: false,
    hidden: false
  },
  {
    dataField: 'updated_at',
    text: 'timestamp (UTC)',
    editable: false
  }
]
