import React from 'react'
import PropTypes from 'prop-types'

const Spacer = ({
  size,
  axis,
  inline = true,
  style = {}
}) => {
  const width = axis === 'vertical' ? 1 : size
  const height = axis === 'horizontal' ? 1 : size
  return (
    <span
      style={{
        display: inline ? 'inline-block' : 'block',
        width,
        minWidth: width,
        height,
        minHeight: height,
        ...style
      }}
    />
  )
}

Spacer.propTypes = {
  size: PropTypes.number,
  axis: PropTypes.string,
  inline: PropTypes.bool,
  style: PropTypes.object
}

export default Spacer
