import React, { useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import PropTypes from 'prop-types'
import './Board.css'

export default function ColorPicker (props) {
  const { initialColor = '#ffffff', onChange } = props
  const [color, setColor] = useState(initialColor)

  const _onChange = (color) => {
    setColor(color)
    if (onChange) {
      onChange(color)
    }
  }

  return (
    <div>
      <HexColorPicker color={color} onChange={_onChange} />

      <div className="color-picker value" style={{ borderLeftColor: color }}>
        Current color is {color}
      </div>

      {/* <div className="color-picker buttons">
        <button onClick={() => _onChange('#c6ad23')}>Choose gold</button>
        <button onClick={() => _onChange('#556b2f')}>Choose green</button>
        <button onClick={() => _onChange('#207bd7')}>Choose blue</button>
      </div> */}
    </div>
  )
}

ColorPicker.propTypes = {
  initialColor: PropTypes.string,
  onChange: PropTypes.func
}
