import React from 'react'
import './Board.css'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import ColorPicker from './ColorPicker'

function WatercolorSettings (props) {
  const onPickerChange = (color) => {
    const target = {
      id: 'canvasColor',
      value: color,
      type: 'color-picker'
    }
    props.valueChange({ target })
  }

  return (
    <>
        <div id="whiteboard-settings">
          <div>
            <Form.Label disabled={props.disabled}>Color Size</Form.Label>
            <Form.Range disabled={props.disabled} defaultValue={props.settings.colorSize} id="colorSize" onChange={props.valueChange} min="50" max="500"/>
            <span>{props.settings.colorSize}</span>
          </div>
          <div>
            <Form.Label disabled={props.disabled}>Deformations</Form.Label>
            <Form.Range disabled={props.disabled} defaultValue={props.settings.deformations} id="deformations" onChange={props.valueChange} min="1" max="5"/>
            <span>{props.settings.deformations}</span>
          </div>
          <div>
            <Form.Label disabled={props.disabled}>Random Seed</Form.Label>
            <Form.Range disabled={props.disabled} defaultValue={props.settings.randomSeed} id="randomSeed" onChange={props.valueChange} min="0" max="999"/>
            <span>{props.settings.randomSeed}</span>
          </div>
          <div>
            <Form.Label disabled={props.disabled}>Sigma</Form.Label>
            <Form.Range disabled={props.disabled} defaultValue={props.settings.sigma} id="sigma" onChange={props.valueChange} step="0.1" min="1.5" max="3.0"/>
            <span>{props.settings.sigma}</span>
          </div>
          <div>
            <Form.Check disabled={props.disabled} checked={props.settings.mask} id="mask" reverse="true"
              type="switch" label="Mask" onChange={props.valueChange} />
          </div>
          <div>
            <Form.Label disabled={props.disabled || !props.settings.mask}>Mask Circles</Form.Label>
            <Form.Range disabled={props.disabled || !props.settings.mask} defaultValue={props.settings.maskCircles} id="maskCircles" onChange={props.valueChange} min="5" max="300"/>
            <span>{props.settings.maskCircles}</span>
          </div>
          <div>
            <Form.Label disabled={props.disabled || !props.settings.mask}>Mask Circle Size</Form.Label>
            <Form.Range disabled={props.disabled || !props.settings.mask} defaultValue={props.settings.maskCircleSize} id="maskCircleSize" onChange={props.valueChange} min="1" max="300"/>
            <span>{props.settings.maskCircleSize}</span>
          </div>
          <div>
            <Form.Check disabled={props.disabled} checked={props.settings.lightColors} id="lightColors" reverse="true"
              type="switch" label="Light Colors" onChange={props.valueChange} />
          </div>
          <div>
            <Form.Check disabled={props.disabled} checked={props.settings.darkColors} id="darkColors" reverse="true"
              type="switch" label="Dark Colors" onChange={props.valueChange} />
          </div>
          <div>
            <Form.Label disabled={props.disabled}>Blend</Form.Label>
            <Form.Select id="blend" disabled={props.disabled} value={props.settings.blend} aria-label="Blend" onChange={props.valueChange}>
              <option value="lighten">lighten</option>
              <option value="darken">darken</option>
            </Form.Select>
          </div>
          <div id="set-background-color">
            <Form.Label>Canvas Color</Form.Label>
            <div className="settings-color-picker">
              <ColorPicker id="canvasColor" onChange={onPickerChange} initialColor={props.settings.canvasColor} />
            </div>
          </div>
        </div>
    </>
  )
}

export default WatercolorSettings

WatercolorSettings.propTypes = {
  settings: PropTypes.object,
  valueChange: PropTypes.func,
  disabled: PropTypes.bool
}
