import React, { useEffect, useState, useCallback } from 'react'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop'
import '../css/Dashboard.css'

function ImagePicker (props) {
  const { controlId, disabled, onChange, label = 'Image', aspect = (4 / 3) } = props

  const [selectedFile, setSelectedFile] = useState()
  const [previewImageUrl, setPreviewImageUrl] = useState()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const onFileSelected = (event) => {
    if (event.target.files) {
      setZoom(1)
      setCrop({ x: 0, y: 0 })
      setSelectedFile(event.target.files[0])
    }
  }

  const onCropComplete = useCallback((croppedArea, cropPixels) => {
    onChange({ croppedAreaPixels: cropPixels, previewImageUrl })
  }, [previewImageUrl])

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    console.log('selectedFile', selectedFile)
    if (!selectedFile) {
      setPreviewImageUrl(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreviewImageUrl(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  return (
    <>
      <Form.Group className="mb-3 form-item" controlId={controlId}>
        <Form.Label className="label-heading">{label}</Form.Label>
        <Form.Control required disabled={disabled} onChange={onFileSelected} type="file"/>
        <Form.Control.Feedback type="invalid">
            Please choose an image.
        </Form.Control.Feedback>
      </Form.Group>
      {selectedFile &&
          <div className="upload-preview">
            <Cropper
              image={previewImageUrl}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              objectFit={'contain'}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
      }
  </>
  )
}

ImagePicker.propTypes = {
  aspect: PropTypes.number,
  label: PropTypes.string,
  controlId: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

export default ImagePicker
