/* eslint-disable no-unreachable */
import React, { useState, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import '../../css/Dashboard.css'
import { auth } from '../../firebase'
import Navbar from '../Navbar'
import { ColumnCodes as ColCodes, ColumnLink as link } from '../../columns/Metadata'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import CollectionChooser from '../CollectionChooser'
import { serverTimestamp } from 'firebase/firestore'
import { serverCall } from '../../lib/utils.js'

export const NAME = ColCodes.SHOPIFY.EXPORT_CSV

function ShopifyExportCsv () {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const [validated, setValidated] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [selectedOrg, setSelectedOrg] = useState('')
  const [selectedOrgName, setSelectedOrgName] = useState('')
  const [selectedSinceDate, setSelectedSinceDate] = useState(null)
  const [published, setPublished] = useState(false)

  const onOrgChange = ({ id, name }) => {
    setSelectedOrg(id)
    setSelectedOrgName(name)
  }

  const onDateChange = (evt) => {
    setSelectedSinceDate(evt.target.value)
  }

  const handleSubmit = async (event) => {
    setError('')
    setSuccess('')

    const form = event.currentTarget
    const formIsValid = form.checkValidity()

    if (!formIsValid) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
    }

    setValidated(true)
    if (!formIsValid) {
      return
    }

    try {
      const params = {
        user: {
          uid: user.uid,
          email: user.email
        },
        published,
        org: selectedOrg,
        orgName: selectedOrgName,
        since: selectedSinceDate,
        status: 'created',
        timestamp: serverTimestamp()
      }

      const result = await serverCall({
        functionName: 'shopify-newShopifyExportCsv',
        functionParams: params,
        confirm: false,
        preSubmit: () => {
          setSubmitting(true)
        }
      })

      const data = result.data
      if (data.error) {
        throw new Error(result.error)
      }

      setSuccess('Created the Shopify Export Csv request.')
      setTimeout(() => {
        setSuccess('')
      }, 3000)

      setValidated(false)
    } catch (e) {
      console.error(e)
      setError(e.toString())
      // don't clear the form on error
    }

    setSubmitting(false)
  }

  function nowISOStringLocal () {
    const utcNow = new Date()
    const offsetInMs = utcNow.getTimezoneOffset() * 60 * 1000
    // substract the offset from now
    const nowLocal = new Date(utcNow - offsetInMs)
    return nowLocal.toISOString().split('.')[0]
  }

  useEffect(() => {
    if (loading) {
      return
    }

    if (!user) {
      return navigate(link(ColCodes.HOME))
    }
  }, [user, loading, navigate])

  return (
    <>
      <Navbar title='New Shopify Export Csv' />
      <div className='form-container'>
        {success &&
          <Alert key="success" variant="success">
            {success}
          </Alert>
        }
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <CollectionChooser controlId={'orgChooser'} collectionName={'Org'}
            collectionNamePlural={'Orgs'} onChange={onOrgChange} disabled={submitting} />
          <Form.Group controlId="dob">
            <Form.Label>Select Date (UTC)</Form.Label>
            <Form.Control required onChange={onDateChange}
              type="datetime-local"
              min="2023-01-01T00:00"
              max={nowISOStringLocal()}
              name="since"
              placeholder="Sessions Since"
            />
            <Form.Control.Feedback type="invalid">
              <div>
                Please select a valid date between Jan 1st, 2023 and now.
              </div>
            </Form.Control.Feedback>
          </Form.Group>
          <div style={{ marginTop: '10px' }}></div>
          <Form.Check
            checked={published}
            disabled={submitting}
            inline
            type="switch"
            id="custom-switch"
            label="Published?"
            onChange={(evt) => { setPublished(evt.target.checked) }}
          />
          {error &&
            <Alert key="danger" variant="danger">
              {error}
            </Alert>
          }
          {submitting &&
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          }
          <div style={{ marginTop: '30px' }}></div>
          <Button disabled={submitting} variant="primary" type="submit">Create New Shopify Export Csv</Button>
        </Form>
      </div>
    </>
  )
}
export default ShopifyExportCsv
