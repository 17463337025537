import React, { useState, useCallback, useEffect } from 'react'
import './Board.css'
import PropTypes from 'prop-types'

function Board (props) {
  const { setPoint, points, debug = false, width: canvasWidth = 1920, height: canvasHeight = 1080, dispersePoints = false } = props
  const canvasRef = React.useRef(null)
  const [ctx, setCtx] = useState({})
  const [canvasOffset, setCanvasOffset] = useState({ x: 0, y: 0 })
  const [drawing, setDrawing] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [pathCount, setPathCount] = useState(0)

  const LINE_WIDTH = 10
  const LINE_COLOR = '#000000'
  const PATH_COUNT_MOD = 4

  const log = useCallback((...args) => {
    if (debug) {
      console.log(...args)
    }
  }, [debug])

  function clearCanvas () {
    const canv = canvasRef.current
    const ctx = canv.getContext('2d')
    ctx.clearRect(0, 0, canv.width, canv.height)
  }

  useEffect(() => {
    const canv = canvasRef.current
    canv.width = canvasWidth
    canv.height = canvasHeight

    const canvCtx = canv.getContext('2d')
    canvCtx.lineJoin = 'round'
    canvCtx.lineCap = 'round'
    canvCtx.lineWidth = LINE_WIDTH
    setCtx(canvCtx)

    const offset = canv.getBoundingClientRect()
    log('offset', offset)
    setCanvasOffset({ x: parseInt(offset.left), y: parseInt(offset.top) })

    if (points.length === 0) {
      clearCanvas()
    }
  }, [log, points, canvasWidth, canvasHeight])

  function handleMouseDown (e) {
    setDrawing(true)
    setPosition({
      x: parseInt(e.clientX - canvasOffset.x),
      y: parseInt(e.clientY - canvasOffset.y)
    })
    setPathCount(1)
  }
  function handleMouseUp () {
    log('path count', pathCount)

    // in case this is a single point click (tolerance of up to 2), we draw the single point
    if (drawing && pathCount <= 2) {
      ctx.strokeStyle = LINE_COLOR
      ctx.beginPath()
      ctx.arc(position.x, position.y, 1 /* radius */, 0, 2 * Math.PI)
      ctx.stroke()
      log('position (point)', position)
      setPoint(position.x, position.y)
    }
    setDrawing(false)
    setPathCount(0)
  }

  function handleMouseMove (e) {
    const mousex = e.clientX - canvasOffset.x
    const mousey = e.clientY - canvasOffset.y
    if (drawing) {
      setPathCount(pathCount + 1)

      ctx.strokeStyle = LINE_COLOR
      ctx.beginPath()
      ctx.moveTo(position.x, position.y)
      ctx.lineTo(mousex, mousey)
      ctx.stroke()

      // only capture every PATH_COUNT_MOD position moves (disperse points) or override
      const capturePoint = !dispersePoints || (pathCount % PATH_COUNT_MOD) === 0
      if (capturePoint) {
        setPoint(mousex, mousey)
      }

      log('position (path)', { x: mousex, y: mousey })
    }
    setPosition({ x: mousex, y: mousey })
  }

  return (
    <div className="board">
      <canvas
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      />
    </div>
  )
}

Board.propTypes = {
  debug: PropTypes.bool,
  dispersePoints: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  points: PropTypes.array,
  setPoint: PropTypes.func
}

export default Board
