import React from 'react'
import {
  ColumnMetadataIndex as ColIndex,
  ColumnCodes as ColCodes
} from './Metadata'
import { updateDoc, doc as firestoreDoc } from 'firebase/firestore'
import { db, auth } from '../firebase'
import Spacer from '../components/Spacer'
import { checkAndExecuteFunction, serverCall, createField, relativeTimestampFormatter, getImage } from '../lib/utils.js'
import LoadingBootstrapIcon from '../components/LoadingBootstrapIcon'

export const NAME = ColCodes.SESSION
export const TITLE = ColIndex[NAME].title

const generateCert = async (id, done) => {
  if (window.confirm('Generate cert?')) {
    const docRef = firestoreDoc(db, NAME, id)
    await updateDoc(docRef, {
      status: 'generate:completed:creation',
      lastUpdatedBy: auth.currentUser.uid,
      lastUpdatedByEmail: auth.currentUser.email
    })
  }
  checkAndExecuteFunction(done)
}

const hideProduct = async (id, done) => {
  if (window.confirm('Really hide product?')) {
    const docRef = firestoreDoc(db, NAME, id)
    await updateDoc(docRef, {
      hideProduct: true,
      lastUpdatedBy: auth.currentUser.uid,
      lastUpdatedByEmail: auth.currentUser.email
    })
  }
  checkAndExecuteFunction(done)
}

const unhideProduct = async (id, done) => {
  if (window.confirm('Really unhide product?')) {
    const docRef = firestoreDoc(db, NAME, id)
    await updateDoc(docRef, {
      hideProduct: false,
      lastUpdatedBy: auth.currentUser.uid,
      lastUpdatedByEmail: auth.currentUser.email
    })
  }
  checkAndExecuteFunction(done)
}

const deleteSession = async (id, done) => {
  const params = {
    user: {
      uid: auth.currentUser.uid,
      email: auth.currentUser.email
    },
    sessionId: id
  }

  const { data } = await serverCall({
    functionName: 'session-deleteSession',
    functionParams: params,
    doConfirm: true,
    confirmMessage: 'Really delete this session?'
  })

  if (data?.error) {
    window.alert(`Error: Could not delete session ${id}.`)
  }
  checkAndExecuteFunction(done)
}

const infoPage = async (id, done) => {
  window.open(`https://gallery.glasscube.tech/show/art/${id}`, '_blank')
  checkAndExecuteFunction(done)
}

const showAlert = (text, done) => {
  window.alert(text)
  checkAndExecuteFunction(done)
}

export const EXPANDROW = {
  renderer: row => (
    <pre>{JSON.stringify(
      {
        ...row,
        timestamp: row.timestamp.toDate().toString()
      },
      null,
      2)
      }</pre>
  ),
  showExpandColumn: true
}

const checkProfileRecord = (profile) => {
  function sortTwo (test, testString, includes, excludes) {
    if (test) {
      includes.push(testString)
    } else {
      excludes.push(testString)
    }
  }

  const includes = []
  const excludes = []

  const s2Wrapper = (test, testString) => {
    return sortTwo(test, testString, includes, excludes)
  }

  s2Wrapper(profile, 'profile')
  s2Wrapper(profile?.org, 'profile.org')
  s2Wrapper(profile?.org?.id, 'profile.org.id')
  s2Wrapper(profile?.org?.name, 'profile.org.name')
  s2Wrapper(profile?.id, 'profile.id')
  s2Wrapper(profile?.name, 'profile.name')
  s2Wrapper(profile?.image, 'profile.image')

  return [includes, excludes]
}

export const COLUMNS = [
  createField({
    dataField: 'id',
    hidden: true
  }),
  createField({
    dataField: 'data',
    hidden: true
  }),
  createField({
    dataField: 'assets',
    hidden: true
  }),
  createField({
    dataField: 'profile',
    formatter: (cell, row) => {
      return cell ? (cell.name ?? 'undefined') : 'unknown'
    }
  }),
  createField({
    dataField: 'assets.watermarked',
    text: 'image',
    formatter: getImage
  }),
  createField({
    dataField: 'assets.certificate',
    text: 'cert',
    formatter: getImage
  }),
  createField({
    dataField: 'profile.org',
    text: 'org',
    formatter: (cell, row) => {
      return cell ? cell.name : 'unknown'
    }
  }),
  createField({
    dataField: 'status'
  }),
  createField({
    dataField: 'hideProduct',
    text: 'hidden',
    formatter: (cell, _) => {
      if (cell) { // if it's hidden, we show in red
        return (<span style={ { color: 'red' } }>{cell.toString()}</span>)
      } else {
        return cell
      }
    }
  }),
  createField({
    dataField: 'timestamp',
    text: 'timestamp (UTC)',
    title: (cell) => cell.toDate().toISOString(),
    formatter: relativeTimestampFormatter
  }),
  createField({
    dataField: 'action',
    isDummyField: true,
    formatter: (cellContent, row) => {
      const spacerSize = 5
      const buttonSize = 25
      const [, profExcludes] = checkProfileRecord(row.profile)

      const profileIncomplete = profExcludes.length > 0
      const profileImageMissingOnly = profExcludes.length === 1 &&
          profExcludes.includes('profile.image')

      const genWatermarkAndCertEnabled =
        row.status === 'generate:completed' &&
        (!row.assets.certificate || !row.assets.watermarked) &&
        !profileImageMissingOnly

      const items = []
      if (genWatermarkAndCertEnabled && !profileIncomplete) {
        items.push(<LoadingBootstrapIcon iconName="Award" onClick={(done) => generateCert(row.id, done)} className="actionButton" size={buttonSize} title="generate watermark+cert" />)
        items.push(<Spacer size={spacerSize} axis="horizontal" />)
      }

      if (profileIncomplete && !profileImageMissingOnly) {
        items.push(<LoadingBootstrapIcon iconName="DatabaseExclamation" onClick={(done) => showAlert(`The profile data is incomplete. Missing: ${profExcludes.join(',')}. The session should be deleted.`, done)} size={buttonSize} className="actionButton" color="red" title="profile incomplete, please delete" />)
      }

      const delSessionEnabled = (row.status === 'generate:completed' || profileIncomplete)
      if (delSessionEnabled) {
        items.push(<LoadingBootstrapIcon iconName="TrashFill" onClick={(done) => deleteSession(row.id, done)} className="actionButton" color="red" size={buttonSize} title="delete" />)
        items.push(<Spacer size={spacerSize} axis="horizontal" />)
      }

      const infoEnabled = row.status === 'generate:completed' && !profileIncomplete && !genWatermarkAndCertEnabled
      if (infoEnabled) {
        items.push(<LoadingBootstrapIcon iconName="InfoCircle" onClick={(done) => infoPage(row.id, done)} size={buttonSize} className="actionButton" color="blue" title="info" />)
        items.push(<Spacer size={spacerSize} axis="horizontal" />)
      }

      if (profileImageMissingOnly) {
        items.push(<LoadingBootstrapIcon iconName="PersonExclamation" onClick={(done) => showAlert('The profile image is missing', done)} size={buttonSize} className="actionButton" color="red" title="profile image missing" />)
      }

      if (delSessionEnabled) {
        if (row.hideProduct) {
          items.push(<LoadingBootstrapIcon iconName="BagCheck" onClick={(done) => unhideProduct(row.id, done)} size={buttonSize} className="actionButton" color="green" title="Unhide Product" />)
          items.push(<Spacer size={spacerSize} axis="horizontal" />)
        } else {
          items.push(<LoadingBootstrapIcon iconName="BagX" onClick={(done) => hideProduct(row.id, done)} size={buttonSize} className="actionButton" color="red" title="Hide Product" />)
          items.push(<Spacer size={spacerSize} axis="horizontal" />)
        }
      }

      return items
    }
  })
]
