import React, { useEffect, useState, useReducer } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import '../css/Dashboard.css'
import { auth, logout } from '../firebase'
import Navbar from './Navbar'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import CardGroup from 'react-bootstrap/CardGroup'
import {
  ColumnMetadataList as ColList,
  ColumnCodes as ColCodes,
  ColumnLink as link
} from '../columns/Metadata'
import { getDatabase, onValue, ref as dbRef } from 'firebase/database'
import { formatDistanceToNow } from 'date-fns'
import FirestoreImage from '../components/FirestoreImage'
import ErrorBoundary from './ErrorBoundary'
import { serverCall } from '../lib/utils.js'
import { CloudDownload } from 'react-bootstrap-icons'

const nullCount = '...'
function displayStatCount (count) {
  if (count === null || count === undefined) {
    return nullCount
  }

  return count
}

function Dashboard () {
  const [user, loading] = useAuthState(auth)
  const [Stats, setStats] = useState({})
  const [workerStatusCleanup, setWorkerStatusCleanup] = useState(false)
  const [csvStatusCleanup, setCsvStatusCleanup] = useState(false)
  const [WorkerStatus, setWorkerStatus] = useState(null)
  const [ShopifyExportCsvStatus, setShopifyExportCsvStatus] = useState(null)
  const navigate = useNavigate()
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  async function runWorkerStatusCleanup () {
    setWorkerStatusCleanup(true)
    return serverCall({
      functionName: 'session-workerStatusCleanup',
      functionParams: {}
    }).finally(() => {
      setWorkerStatusCleanup(false)
    })
  }

  async function runCsvStatusCleanup () {
    setCsvStatusCleanup(true)
    return serverCall({
      functionName: 'shopify-exportCsvStatusCleanup',
      functionParams: {}
    }).finally(() => {
      setCsvStatusCleanup(false)
    })
  }

  useEffect(() => {
    if (loading) {
      return
    }

    if (!user) {
      return navigate(link(ColCodes.HOME))
    }

    const database = getDatabase()
    const statsRef = dbRef(database, 'Stats')
    const workerStatusRef = dbRef(database, 'WorkerStatus')
    const shopifyExportCsvStatusRef = dbRef(database, 'ShopifyExportCsvStatus')

    onValue(statsRef, (snapshot) => {
      const data = snapshot.val()
      setStats(data)
    })

    onValue(workerStatusRef, (snapshot) => {
      const data = snapshot.val()
      setWorkerStatus(data)
    })

    onValue(shopifyExportCsvStatusRef, (snapshot) => {
      const data = snapshot.val()
      setShopifyExportCsvStatus(data)
    })

    async function handleLogin () {
      try {
        const idTokenResult = await auth.currentUser.getIdTokenResult()
        // confirm the user is an Admin
        const isAdmin = !!idTokenResult.claims.admin
        if (!isAdmin) {
          const { displayName, email } = auth.currentUser
          console.log(`Unauthorized user: ${displayName} (${email})`)
          logout()
          const messages = [
            'You are unauthorized to log in to this app.',
            'This login has been logged.',
            'Please contact the server admin if you are actually authorized to access the system.'
          ]
          return navigate(link(ColCodes.UNAUTHORIZED_ACCESS), { state: { message: messages.join('\n') } })
        }
      } catch (error) {
        console.log('Error', error)
      }
    }
    handleLogin()
  }, [user, loading, navigate])

  // to refresh dates
  setInterval(() => {
    forceUpdate()
  }, 60000)

  const firestoreImageDetails = {
    width: 226,
    height: 127,
    backgroundColor: 'white'
  }

  return (
    <>
      <Navbar title={ColCodes.SUMMARY} />
      <h1 className="dashboard">Stats</h1>
      <CardGroup>
        {ColList
          .filter((item) => !item.hidden)
          .map((item, index) =>
            <Card className="card-container" key={index} border="secondary" style={{ backgroundColor: item.backgroundColor ? item.backgroundColor : 'inherit', minWidth: '15rem', maxWidth: '15rem' }}>
              <Card.Link style={{ textDecoration: 'none', color: 'black' }} href={item.link}>
                <Card.Body>
                  <Card.Title>
                    {item.title}
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {item.color ? <span style={{ color: item.color }}>{displayStatCount(Stats[item.code])}</span> : displayStatCount(Stats[item.code])}
                  </Card.Subtitle>
                  <Card.Text>
                    {item.text}
                  </Card.Text>
                </Card.Body>
              </Card.Link>
            </Card>
          )}
      </CardGroup>
      {WorkerStatus &&
        <>
          <hr />
          <h1 className="dashboard">Image Processing&nbsp;
            <Button size="sm" disabled={workerStatusCleanup} onClick={runWorkerStatusCleanup}>cleanup</Button>
            {workerStatusCleanup &&
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Cleaning...</span>
            </Spinner>
            }
          </h1>
        </>
      }
      <ErrorBoundary>
      <CardGroup>
        {WorkerStatus &&
          Object.keys(WorkerStatus)
            .map(key => ({ ...WorkerStatus[key], key }))
            .map((item, index) =>
              <Card key={item.key} border="secondary" style={{ minWidth: '15rem', maxWidth: '15rem' }}>
                    {item.assets?.created && !item.assets?.watermarked &&
                      <FirestoreImage storagePath={item.assets.created} style={{ ...firestoreImageDetails }} />
                    }
                    {item.assets?.watermarked &&
                      <FirestoreImage storagePath={item.assets.watermarked} style={{ ...firestoreImageDetails }} />
                    }
                    {item.assets?.certificate &&
                      <FirestoreImage storagePath={item.assets.certificate} style={{ ...firestoreImageDetails }} />
                    }
                    {!item.assets?.created && !item.assets?.watermarked &&
                      <div style={{ width: 239, height: 135, backgroundColor: 'lightgrey' }}>
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    }
                  <Card.Body>
                    <Card.Title>
                      {item.status === 'generate:completed' ? `${item.status} ✅` : item.status}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                    {formatDistanceToNow(new Date(item.timestamp), { addSuffix: true })}
                    </Card.Subtitle>
                    <Card.Text>
                    </Card.Text>
                  </Card.Body>
              </Card>
            )}
      </CardGroup>
      {ShopifyExportCsvStatus &&
        <>
          <hr />
          <h1 className="dashboard">Shopify Export CSV&nbsp;
          <Button size="sm" disabled={csvStatusCleanup} onClick={runCsvStatusCleanup}>cleanup</Button>
          {csvStatusCleanup &&
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Cleaning...</span>
          </Spinner>
          }
          </h1>
        </>
      }
      <CardGroup>
        {ShopifyExportCsvStatus &&
          Object.keys(ShopifyExportCsvStatus)
            .map(key => ({ ...ShopifyExportCsvStatus[key], key }))
            .map((item, index) => {
              const isExpired = Date.now() > new Date(item.expiry).valueOf()
              return <Card key={item.key} border="secondary" style={{ minWidth: '15rem', maxWidth: '15rem' }}>
                  <Card.Body>
                    <Card.Title>
                      {item.status === 'completed' ? `${item.status} ✅` : (item.status === 'error') ? `${item.status} ❌` : item.status}
                      {isExpired &&
                        <span style={ { color: 'red' } }>(expired)</span>
                      }
                      {!isExpired && item.downloadUrl &&
                        <a href={item.downloadUrl}><CloudDownload className="actionButton" size={25} color="blue"/></a>
                      }
                    </Card.Title>
                    <Card.Subtitle>
                      {item.error &&
                        <span>{item.error}</span>
                      }
                    </Card.Subtitle>
                    <Card.Text>
                      {item.orgName && item.since &&
                        <span title={new Date(item.since).toString()}>{item.orgName} ({item.since})</span>
                      }
                    </Card.Text>
                  </Card.Body>
              </Card>
            }
            )}
      </CardGroup>
      </ErrorBoundary>
    </>
  )
}
export default Dashboard
