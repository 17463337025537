import React from 'react'
import {
  ColumnMetadataIndex as ColIndex,
  ColumnCodes as ColCodes
} from './Metadata'
import { checkAndExecuteFunction, serverCall, createField, relativeTimestampFormatter } from '../lib/utils.js'
import { auth } from '../firebase'
import LoadingBootstrapIcon from '../components/LoadingBootstrapIcon'

const download = async (url, done) => {
  window.open(url, '_blank')
  checkAndExecuteFunction(done)
}

const deleteRecord = async (id, done) => {
  const params = {
    user: {
      uid: auth.currentUser.uid,
      email: auth.currentUser.email
    },
    shopifyExportCsvId: id
  }

  const { data } = await serverCall({
    functionName: 'shopify-deleteShopifyExportCsv',
    functionParams: params,
    doConfirm: true,
    confirmMessage: 'Really delete this export request?'
  })

  if (data?.error) {
    window.alert(`Error: Could not delete ShopifyExportCsv ${id}.`)
  }
  checkAndExecuteFunction(done)
}

export const EXPANDROW = {
  renderer: row => (
    <pre>{JSON.stringify(
      {
        ...row,
        since: row.since.toDate().toString(),
        timestamp: row.timestamp.toDate().toString(),
        expiry: row.expiry.toDate().toString()
      },
      null,
      2)
      }</pre>
  ),
  showExpandColumn: true
}

export const COLUMNS = [
  createField({
    dataField: 'id',
    hidden: true
  }),
  createField({
    dataField: 'orgName'
  }),
  createField({
    dataField: 'status',
    title: (cell, row) => row.error ? row.error : '',
    formatter: (cell, row) => {
      if (cell === 'error') {
        return <span style={ { color: 'red' } }>{cell}</span>
      } else {
        return cell
      }
    }
  }),
  createField({
    dataField: 'since',
    text: 'since (UTC)',
    title: (cell) => cell ? cell.toDate().toString() : 'unknown',
    formatter: relativeTimestampFormatter
  }),
  createField({
    dataField: 'user',
    text: 'requested by',
    formatter: (cell) => cell.email
  }),
  createField({
    dataField: 'timestamp',
    text: 'timestamp (UTC)',
    title: (cell) => cell ? cell.toDate().toString() : 'unknown',
    formatter: relativeTimestampFormatter
  }),
  createField({
    dataField: 'expiry',
    text: 'expiry (UTC)',
    title: (cell) => cell ? cell.toDate().toString() : 'unknown',
    formatter: relativeTimestampFormatter
  }),
  createField({
    isDummyField: true,
    dataField: 'action',
    text: 'action',
    formatter: (cell, row) => {
      const completed = row.status === 'completed'
      const error = row.status === 'error'
      const items = []

      if (row.expiry) {
        const expiry = row.expiry.toDate()
        const isExpired = Date.now() > expiry.valueOf()

        if (row.downloadUrl && completed) {
          if (isExpired) {
            items.push(<span style={ { color: 'red' } }>expired</span>)
          } else {
            items.push(<LoadingBootstrapIcon iconName="CloudDownload" onClick={(done) => download(row.downloadUrl.toString(), done)} size={25} className="actionButton" color="blue" title="download" />)
          }
        }
      }

      if (completed || error) {
        items.push(<LoadingBootstrapIcon iconName="TrashFill" onClick={(done) => deleteRecord(row.id, done)} size={25} className="actionButton" color="red" title="delete" />)
      }

      return items
    }
  })
]

export const NAME = ColCodes.SHOPIFY.EXPORT_CSV
export const TITLE = ColIndex[NAME].title
