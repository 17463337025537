import React from 'react'
import LoadingButton from '../components/LoadingButton'
import { formatDistanceToNow } from 'date-fns'
import { db, auth } from '../firebase'
import { updateDoc, doc } from 'firebase/firestore'
import {
  ColumnMetadataIndex as ColIndex,
  ColumnCodes as ColCodes
} from './Metadata'

export const NAME = ColCodes.APIKEY
export const TITLE = ColIndex[NAME].title

function isExpired (date) {
  return date <= new Date()
}

function enabledFormatter (cell, row) {
  const expirationDate = row.expiration.toDate()
  if (!isExpired(expirationDate) || cell === false) {
    return cell.toString()
  } else {
    return (
      <span style={ { color: 'red' } }>{cell.toString()}</span>
    )
  }
}

function relativeTimestampFormatter (cell, row) {
  const cellDate = cell && cell.toDate()
  const relativeDate = cellDate ? formatDistanceToNow(cellDate, { addSuffix: true }) : ''

  if (isExpired(cellDate)) {
    return (<span style={ { color: 'red' } }>{relativeDate}</span>)
  } else {
    return relativeDate
  }
}

const disableApiKey = async (id, done) => {
  const docRef = doc(db, NAME, id)
  await updateDoc(docRef, {
    enabled: false,
    lastUpdatedBy: auth.currentUser.uid,
    lastUpdatedByEmail: auth.currentUser.email
  })
  done()
}

const enableApiKey = async (id, done) => {
  const docRef = doc(db, NAME, id)
  await updateDoc(docRef, {
    enabled: true,
    lastUpdatedBy: auth.currentUser.uid,
    lastUpdatedByEmail: auth.currentUser.email
  })
  done()
}

export const COLUMNS = [
  {
    dataField: 'id',
    text: 'id',
    hidden: true
  },
  {
    dataField: 'label',
    text: 'label',
    editable: false
  },
  {
    dataField: 'key',
    text: 'key',
    editable: false
  },
  {
    dataField: 'expiration',
    text: 'expiration (UTC)',
    title: (cell) => cell.toDate().toISOString(),
    formatter: relativeTimestampFormatter,
    editable: false
  },
  {
    dataField: 'timestamp',
    text: 'timestamp (UTC)',
    title: (cell) => cell && cell.toDate().toISOString(),
    formatter: relativeTimestampFormatter,
    editable: false
  },
  {
    dataField: 'scopes',
    text: 'scopes',
    editable: false
  },
  {
    dataField: 'enabled',
    text: 'enabled',
    formatter: enabledFormatter,
    editable: false
  },
  {
    dataField: 'action',
    isDummyField: true,
    text: 'action',
    editable: false,
    formatter: (cellContent, row) => {
      const expired = isExpired(row.expiration.toDate())
      const enabled = !!row.enabled
      const noAction = expired && !enabled
      if (noAction) { // special case
        return <></>
      } else if (enabled) {
        return (
          <LoadingButton
            text="disable"
            variant="outline-danger"
            size="sm"
            handleClick={(done) => disableApiKey(row.id, done)}
          />
        )
      } else {
        return (
          <LoadingButton
            text="enable"
            variant="outline-success"
            size="sm"
            handleClick={(done) => enableApiKey(row.id, done)}
          />
        )
      }
    },
    events: {
      onClick: e => e.stopPropagation()
    }
  }
]
