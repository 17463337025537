// import React, { useState } from 'react'
import React from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import '../css/Navbar.css'
import { auth, logout } from '../firebase'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavbarToggle from 'react-bootstrap/NavbarToggle'

import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import PropTypes from 'prop-types'
import {
  ColumnCodes as ColCodes,
  ColumnLink as link,
  ColumnTitle as title
} from '../columns/Metadata'

function Navigation (props) {
  const [user] = useAuthState(auth)

  const isDisabled = (navText) => props.title === navText
  const navDropDown = (code) => (
    <NavDropdown.Item
      href={link(code)}
      disabled={isDisabled(title(code))}>
        {title(code)}
    </NavDropdown.Item>
  )
  const navLink = (code) => (
    <Nav.Link
      href={link(code)}
      disabled={isDisabled(title(code))}>
        {title(code)}
    </Nav.Link>
  )
  const PROJECT_ID = process.env.REACT_APP_PROJECT_ID
  const FIREBASE_CONSOLE_URL = `https://console.firebase.google.com/project/${PROJECT_ID}`
  const LOG_URLS = {
    LOG_EXPLORER: `https://console.cloud.google.com/logs/query;query=%28resource.type%3D%22cloud_function%22%20%20%29%20OR%20%28resource.type%3D%22cloud_run_revision%22%20%20%29?authuser=0&project=${PROJECT_ID}`,
    CLOUD_FUNCTIONS: `https://console.cloud.google.com/functions/list?authuser=0&project=${PROJECT_ID}`,
    ERRORS: `https://console.cloud.google.com/errors?authuser=0&project=${PROJECT_ID}`
  }

  return (
    <Navbar collapseOnSelect bg="light" expand="lg">
      <NavbarToggle aria-controls="navbarScroll" data-bs-target="#navbarScroll" />
      <Nav>
      <Container>
        <Navbar.Brand href="/">
            <img
              src="/images/glasscube.png"
              width="120"
              height="20"
            />
            <span className="title">{props.title}</span>
          </Navbar.Brand>
        </Container>
        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
        {navLink(ColCodes.SUMMARY)}
        <NavDropdown title="Artist" id="basic-nav-dropdown">
            {navDropDown(ColCodes.SESSION)}
            {navDropDown(ColCodes.PROFILE)}
            {navDropDown(ColCodes.ORG)}
          </NavDropdown>
          <NavDropdown title="Artwork" id="basic-nav-dropdown">
            {navDropDown(ColCodes.CANVAS)}
            {navDropDown(ColCodes.WATERMARK)}
            {navDropDown(ColCodes.ARCHIVED)}
            {navDropDown(ColCodes.COMPLETED)}
          </NavDropdown>
          <NavDropdown title="Settings" id="basic-nav-dropdown">
            {navDropDown(ColCodes.UNAUTHORIZED)}
            {navDropDown(ColCodes.ADMIN)}
            {navDropDown(ColCodes.APIKEY)}
          </NavDropdown>
          <NavDropdown title="Backend" id="basic-nav-dropdown">
            <NavDropdown.Item target="_blank" href={FIREBASE_CONSOLE_URL}>Firebase Console</NavDropdown.Item>
            <NavDropdown.Item target="_blank" href={LOG_URLS.LOG_EXPLORER}>Log Explorer</NavDropdown.Item>
            <NavDropdown.Item target="_blank" href={LOG_URLS.CLOUD_FUNCTIONS}>Cloud Functions</NavDropdown.Item>
            <NavDropdown.Item target="_blank" href={LOG_URLS.ERRORS}>Errors</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={(user && user.displayName) || 'Loading...'} id="basic-nav-dropdown">
            <NavDropdown.Item href="/" onClick={logout}>Sign Out</NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
        </Nav>
      </Navbar>
  )
}

Navigation.propTypes = {
  title: PropTypes.string
}

export default Navigation
