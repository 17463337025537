import React, { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import '../css/Dashboard.css'
import { auth } from '../firebase'
import Navbar from './Navbar'
import DataTable from './DataTable'
import Spinner from 'react-bootstrap/Spinner'
import { COLUMNS, EXPANDROW } from '../columns/Shopify'
import { ColumnCodes as ColCodes, ColumnLink as link, ColumnTitle } from '../columns/Metadata'
import { orderBy } from 'firebase/firestore'
import PropTypes from 'prop-types'

function Shopify (props) {
  const { columnCode } = props

  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  useEffect(() => {
    if (loading) {
      return
    }

    if (!user) {
      return navigate(link(ColCodes.HOME))
    }
  }, [user, loading, navigate])

  return (
    <>
      <Navbar title={ColumnTitle(columnCode)} />
      {loading &&
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      }
      {!loading &&
        <>
          <DataTable
            collectionName={columnCode}
            columns={COLUMNS}
            expandRow={EXPANDROW}
            querySort={[orderBy('updated_at', 'desc')]}
          />
        </>
      }
    </>
  )
}

Shopify.propTypes = {
  columnCode: PropTypes.string
}

export default Shopify
