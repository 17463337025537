import React from 'react'
import PropTypes from 'prop-types'

function PrevNextPagination (paginationProps) {
  const { prevPage, nextPage, page, totalSize, sizePerPage, prePageText, nextPageText } = paginationProps
  const maxPages = Math.ceil(totalSize / sizePerPage)

  return (
    <div className="prevNextPagination">
      {maxPages > 0 &&
        <span>Total: {totalSize}, Page: {page}/{maxPages}</span>
      }
      &nbsp;
      <button onClick={prevPage} disabled={page === 1}>{prePageText}</button>
      <button onClick={nextPage} disabled={page === maxPages}>{nextPageText}</button>
    </div>
  )
}

PrevNextPagination.propTypes = {
  prePageText: PropTypes.string,
  nextPageText: PropTypes.string,
  page: PropTypes.number,
  paginationSize: PropTypes.number,
  prevPage: PropTypes.func,
  nextPage: PropTypes.func
}

export default PrevNextPagination
