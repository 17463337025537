import React, { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate, useLocation } from 'react-router-dom'
import '../css/Dashboard.css'
import { auth } from '../firebase'
import Navbar from './Navbar'
import DataTable from './DataTable'
import Spinner from 'react-bootstrap/Spinner'
import { COLUMNS, EXPANDROW, TITLE, NAME } from '../columns/ShopifyExportCsv'
import { ColumnCodes as ColCodes, ColumnLink as link } from '../columns/Metadata'
import { orderBy } from 'firebase/firestore'
import Button from 'react-bootstrap/Button'

function ShopifyExportCsv () {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const location = useLocation()

  const newExport = `${location.pathname}/new`

  useEffect(() => {
    if (loading) {
      return
    }

    if (!user) {
      return navigate(link(ColCodes.HOME))
    }
  }, [user, loading, navigate])

  return (
    <>
      <Navbar title={TITLE} />
      {loading &&
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      }
      {!loading &&
        <>
          <Button href={newExport}>New Export</Button>
          <DataTable
            collectionName={NAME}
            columns={COLUMNS}
            expandRow={EXPANDROW}
            querySort={[orderBy('timestamp', 'desc')]}
          />
        </>
      }
    </>
  )
}

ShopifyExportCsv.propTypes = {
}

export default ShopifyExportCsv
