import { initializeApp } from 'firebase/app'
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth'
import { getStorage } from 'firebase/storage'

import {
  getFirestore,
  enableIndexedDbPersistence
  // query,
  // getDocs,
  // collection,
  // where,
  // addDoc,
} from 'firebase/firestore'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const googleProvider = new GoogleAuthProvider()
const storage = getStorage(app)

enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      console.error('indexeddb persistence failed, persistence only for one tab at a time')
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      console.error('the current browser does not support all features required to enable persistence')
    }
  })

const signInWithGoogle = async () => {
  try {
    await setPersistence(auth, browserLocalPersistence)
    const { user } = await signInWithPopup(auth, googleProvider)
    const { displayName, email } = user
    console.log(`Logged in: ${displayName} (${email})`)
  } catch (err) {
    // Handle Errors here.
    const { code, email, message } = err
    console.error(`code: ${code} message: ${message}, email:${email}`)
  }
}

const logout = () => {
  signOut(auth)
}

export {
  storage,
  auth,
  db,
  signInWithGoogle,
  logout
}
