import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import './Whiteboard.css'
import { auth } from '../../firebase'
import Navbar from '../Navbar'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import { ColumnCodes as ColCodes, ColumnLink as link } from '../../columns/Metadata'
import Board from '../Board/Board'
import WatercolorSettings from '../Board/WatercolorSettings'
import { where } from 'firebase/firestore'
import { getWatercolorSettings, setWatercolorSettings as saveWatercolorSettings } from '../../lib/watercolorSettings'
import CollectionChooser from '../CollectionChooser'
import { serverCall } from '../../lib/utils.js'

const MemoizedBoard = React.memo(Board, propsAreEqual)
function propsAreEqual (prev, next) {
  // only re-render if the points are different
  return JSON.stringify(prev.points) === JSON.stringify(next.points)
}

async function copyTextToClipboard (text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand('copy', true, text)
  }
}

function Whiteboard () {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const [info, setInfo] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [dispersePoints, setDispersePoints] = useState(true)
  const [openSettings, setOpenSettings] = useState(false)
  const [watercolorSettings, setWatercolorSettings] = useState({})
  const [points, setPoints] = useState([])
  const [selectedOrg, setSelectedOrg] = useState('')
  const [selectedOrgName, setSelectedOrgName] = useState('')
  const [selectedProfile, setSelectedProfile] = useState('')
  const [selectedProfileName, setSelectedProfileName] = useState()
  const [selectedProfileImage, setSelectedProfileImage] = useState()
  const [validated, setValidated] = useState(false)

  const SCALE_FACTOR = 2
  const TARGET_CANVAS_SIZE = [1920, 1080]
  const canvasSize = { width: TARGET_CANVAS_SIZE[0] / SCALE_FACTOR, height: TARGET_CANVAS_SIZE[1] / SCALE_FACTOR }

  const onOrgChange = ({ id, name }) => {
    setSelectedOrg(id)
    setSelectedOrgName(name)
  }

  const onProfileChange = ({ id, name, rest }) => {
    setSelectedProfile(id)
    setSelectedProfileName(name)
    setSelectedProfileImage(rest.image)
  }

  const copyToClipboard = () => {
    // copy points and settings
    const data = {
      points, // the points are already scaled
      watercolorSettings,
      canvasSize: TARGET_CANVAS_SIZE
    }
    copyTextToClipboard(JSON.stringify(data, null, 2))
    setInfo('Copied points and settings to the clipboard')
  }

  const setNumberOfPoints = (num) => {
    const elem = document.getElementById('point-count')
    elem.textContent = num
  }

  const setPoint = (x, y) => {
    points.push({ x: x * SCALE_FACTOR, y: y * SCALE_FACTOR })
    setNumberOfPoints(points.length)

    if (points.length > 0) {
      setError('')
    }
  }

  function clearWhiteboard () {
    // clear the points array
    setPoints([])
    setNumberOfPoints(0)
    setDispersePoints(true)
    setSelectedOrg('')
    setSelectedOrgName('')
    setSelectedProfile('')
    setSelectedProfileName('')
    setSelectedProfileImage('')
  }

  const uniquePoints = () => {
    return new Set(points.map(point => `${point.x},${point.y}`))
  }

  useEffect(() => {
    if (loading) {
      return
    }

    if (!user) {
      return navigate(link(ColCodes.HOME))
    }

    try {
      setWatercolorSettings(getWatercolorSettings())
    } catch (e) {
      console.error(e)
    }

    setSuccess('')
    setError('')
    setSubmitting(false)
  }, [user, loading, navigate])

  const handleDispersePoints = (event) => {
    const newValue = event.target.checked
    clearWhiteboard()
    setDispersePoints(newValue)
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget
    const formIsValid = form.checkValidity()

    if (!formIsValid) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
    }

    setValidated(true)
    if (!formIsValid) {
      return
    }

    const sessionDataPoints = Array.from(uniquePoints())
    if (sessionDataPoints.length < 2) {
      setError('Must have at least two data points.')
      return
    }

    console.log('number of points to render', sessionDataPoints.length)
    const sessionData = sessionDataPoints.join('\n')

    setSubmitting(true)
    setError('')

    const { data } = await serverCall({
      functionName: 'session-newSession',
      functionParams: {
        canvas: TARGET_CANVAS_SIZE,
        sessionData,
        settings: watercolorSettings,
        profile: {
          image: selectedProfileImage,
          id: selectedProfile,
          name: selectedProfileName,
          org: {
            id: selectedOrg,
            name: selectedOrgName
          }
        },
        user: {
          uid: user.uid,
          email: user.email
        }
      }
    })

    if (data?.status === 'error') {
      setError(data?.error)
    } else {
      setSuccess('Created a new session.')
    }

    setSubmitting(false)
    setValidated(false)
    setSelectedOrg('')
    setSelectedOrgName('')
    setSelectedProfile('')
    setSelectedProfileName('')
    setSelectedProfileImage('')
  }

  function waterColorSettingsChange (evt) {
    const target = evt.target

    const exclusive = {
      lightColors: 'darkColors',
      darkColors: 'lightColors'
    }
    const isCheckbox = target.type === 'checkbox'
    const isSelect = target.type === 'select-one'
    const isColorPicker = target.type === 'color-picker'
    const value = isCheckbox ? target.checked : (isSelect || isColorPicker) ? target.value : Number(target.value)

    const newSettings = {
      ...watercolorSettings,
      [target.id]: value
    }

    if (isCheckbox && target.checked === true) {
      const exclusiveId = exclusive[target.id]
      newSettings[exclusiveId] = false // opposite
    }

    setWatercolorSettings(newSettings) // useState
    saveWatercolorSettings(newSettings) // localStorage
  }

  return (
    <>
      <Navbar title='New Session (Whiteboard)' />
      <ToastContainer className="p-3" position="position-fixed">
        {info &&
          <Toast onClose={() => setInfo('')} show={true} delay={3000} autohide>
              <Toast.Header>
                <strong className="me-auto">Info</strong>
                <small></small>
              </Toast.Header>
              <Toast.Body>{info}</Toast.Body>
          </Toast>
        }
        {success &&
            <Toast bg="success" onClose={() => setSuccess('')} show={true} delay={3000} autohide>
              <Toast.Header>
                <strong className="me-auto">Success</strong>
                <small></small>
              </Toast.Header>
              <Toast.Body>{success}</Toast.Body>
            </Toast>
        }
        {error &&
          <Toast bg="warning" onClose={() => setError('')} show={true}>
              <Toast.Header>
                <strong className="me-auto">Error</strong>
                <small></small>
              </Toast.Header>
              <Toast.Body>{error}</Toast.Body>
          </Toast>
        }
      </ToastContainer>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <MemoizedBoard points={points} setPoint={setPoint} width={canvasSize.width} height={canvasSize.height} dispersePoints={dispersePoints} debug={false}/>
        <Button variant="secondary" onClick={clearWhiteboard} disabled={submitting}>Clear</Button> &nbsp;
        <Button variant="primary" type="submit" disabled={submitting}>Create New Session</Button> &nbsp;
        <Form.Check
          checked={dispersePoints}
          onChange={handleDispersePoints}
          disabled={submitting}
          inline
          type="switch"
          id="custom-switch"
          label="Disperse Points"
        /> &nbsp;
        {submitting &&
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        }
        [<span id="point-count">0</span> Points]&nbsp;
        <Button
          variant="light"
          onClick={() => setOpenSettings(!openSettings)}
          title={openSettings ? 'close watercolor settings' : 'open watercolor settings'}
        >⚙️</Button>
        <Button
          variant="light"
          onClick={() => copyToClipboard()}
          title="copy points and settings to the clipboard"
        >📋</Button>
        <hr />
        <div className="collection-chooser">
          <CollectionChooser controlId={'orgChooser'} collectionName={'Org'} collectionNamePlural={'Orgs'} onChange={onOrgChange}
            disabled={submitting} value={selectedOrg} />
        </div>
        <div className="collection-chooser">
          <CollectionChooser controlId={'profileChooser'} collectionName={'Profile'} collectionNamePlural={'Profiles'} onChange={onProfileChange}
           disabled={submitting} value={selectedProfile} queryConstraints={selectedOrg ? [where('org', '==', selectedOrg)] : null}/>
        </div>
      </Form>
      {openSettings &&
        <WatercolorSettings disabled={submitting} settings={watercolorSettings} valueChange={waterColorSettingsChange}></WatercolorSettings>
      }
    </>
  )
}

export default Whiteboard
