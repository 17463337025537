import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import { collection, query, onSnapshot } from 'firebase/firestore'
import { db } from '../firebase'

function CollectionChooser (props) {
  const { collectionName, collectionNamePlural, onChange, controlId, value = '', queryConstraints = [] } = props

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState(value)

  async function fetchCollection () {
    setLoading(true)
    const q = query(collection(db, collectionName), ...queryConstraints)
    // return the unsubscribe function
    return onSnapshot(q, (querySnapshot) => {
      setData(querySnapshot.docs.map((doc) => {
        const data = doc.data()
        return { id: doc.id, ...data }
      }))
      setLoading(false)
    })
  }

  useEffect(() => {
    // if queryConstraints is null, we don't fetch
    if (queryConstraints !== null) {
      return fetchCollection()
    }
  }, [queryConstraints])

  useEffect(() => {
    setSelectedItem(value)
  }, [value])

  const getItem = (id) => {
    const result = data.filter(item => item.id === id)
    if (result.length === 1) {
      return result[0]
    }
  }

  const inputChange = (event) => {
    const value = event.target.value
    const item = getItem(value)
    const payload = {
      id: value,
      name: item.name,
      rest: item
    }
    onChange(payload)
    setSelectedItem(value)
  }

  const vowels = ['a', 'e', 'i', 'o', 'u']
  const indefiniteArticle = (str) => {
    const first = str.toLowerCase().charAt(0)
    return vowels.includes(first) ? 'an' : 'a'
  }

  return (
    <Form.Group className="mb-3 form-item" controlId={controlId}>
      <Form.Label className="label-heading">{collectionName}</Form.Label>
      <Form.Select required disabled={props.disabled} onChange={inputChange} aria-label={`Select ${collectionName}`} value={selectedItem}>
        {data.length === 0
          ? loading ? <option value="">{`--Loading ${collectionNamePlural}...`}--</option> : <option value="">{`--No ${collectionNamePlural} available--`}</option>
          : <option value="">{`--Please choose ${indefiniteArticle(collectionName)} ${collectionName}--`}</option>
        }
        {
          data && data.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
        }
      </Form.Select>
      <Form.Control.Feedback type="invalid">
          {`Please choose ${indefiniteArticle(collectionName)} ${collectionName}.`}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

CollectionChooser.propTypes = {
  value: PropTypes.string,
  controlId: PropTypes.string,
  queryConstraints: PropTypes.array,
  collectionName: PropTypes.string,
  collectionNamePlural: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

export default CollectionChooser
