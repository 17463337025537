import React from 'react'
import '../css/App.css'
import Login from './Login'
import Dashboard from './Dashboard'
import Sessions from './Sessions'
import Profiles from './Profiles'
import Orgs from './Orgs'
import Canvas from './Canvas'
import Watermark from './Watermark'
import Archived from './Archived'
import Completed from './Completed'
import APIKeys from './APIKeys'
import UnauthorizedUsers from './UnauthorizedUsers'
import AdminUsers from './AdminUsers'
import UnauthorizedAccess from '../UnauthorizedAccess'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { ColumnCodes as ColCodes, ColumnLink as link } from '../columns/Metadata'

import ShowArt from './ShowArt'
import NewProfile from './new/Profile'
import NewOrg from './new/Org'
import NewSession from './new/Session'
import Whiteboard from './new/Whiteboard'
import EditOrg from './edit/Org'

import Shopify from './Shopify'
import ShopifyExportCsv from './ShopifyExportCsv'
import NewShopifyExportCsv from './new/ShopifyExportCsv'

function App () {
  return (
    <div className="app">
      <Router>
        <Routes>
        <Route exact path={link(ColCodes.UNAUTHORIZED_ACCESS)} element={<UnauthorizedAccess />} />
          <Route exact path={link(ColCodes.HOME)} element={<Login />} />
          <Route exact path={link(ColCodes.SUMMARY)} element={<Dashboard />} />
          <Route exact path={link(ColCodes.SESSION)} element={<Sessions />} />
          <Route exact path={link(ColCodes.SESSION) + '/:filter/:id'} element={<Sessions />} />
          <Route exact path={link(ColCodes.PROFILE)} element={<Profiles />} />
          <Route exact path={link(ColCodes.PROFILE) + '/:orgId'} element={<Profiles />} />
          <Route exact path={link(ColCodes.ORG)} element={<Orgs />} />
          <Route exact path={link(ColCodes.CANVAS)} element={<Canvas />} />
          <Route exact path={link(ColCodes.WATERMARK)} element={<Watermark />} />
          <Route exact path={link(ColCodes.ARCHIVED)} element={<Archived />} />
          <Route exact path={link(ColCodes.COMPLETED)} element={<Completed />} />
          <Route exact path={link(ColCodes.APIKEY)} element={<APIKeys />} />
          <Route exact path={link(ColCodes.UNAUTHORIZED)} element={<UnauthorizedUsers />} />
          <Route exact path={link(ColCodes.ADMIN)} element={<AdminUsers />} />

          <Route exact path={link(ColCodes.PROFILE) + '/new'} element={<NewProfile />} />
          <Route exact path={link(ColCodes.ORG) + '/new'} element={<NewOrg />} />
          <Route exact path={link(ColCodes.ORG) + '/edit'} element={<EditOrg />} />
          <Route exact path={link(ColCodes.SESSION) + '/new'} element={<NewSession />} />
          <Route exact path={link(ColCodes.SESSION) + '/whiteboard'} element={<Whiteboard />} />

          <Route exact path={link(ColCodes.SHOPIFY.EXPORT_CSV)} element={<ShopifyExportCsv />} />
          <Route exact path={link(ColCodes.SHOPIFY.EXPORT_CSV) + '/new'} element={<NewShopifyExportCsv />} />

          <Route exact path={link(ColCodes.SHOPIFY.ORDER_CANCEL)} element={<Shopify columnCode={ColCodes.SHOPIFY.ORDER_CANCEL} />} />
          <Route exact path={link(ColCodes.SHOPIFY.ORDER_CREATE)} element={<Shopify columnCode={ColCodes.SHOPIFY.ORDER_CREATE} />} />
          <Route exact path={link(ColCodes.SHOPIFY.ORDER_DELETE)} element={<Shopify columnCode={ColCodes.SHOPIFY.ORDER_DELETE} />} />
          <Route exact path={link(ColCodes.SHOPIFY.ORDER_EDIT)} element={<Shopify columnCode={ColCodes.SHOPIFY.ORDER_EDIT} />} />
          <Route exact path={link(ColCodes.SHOPIFY.ORDER_FULFILLMENT)} element={<Shopify columnCode={ColCodes.SHOPIFY.ORDER_FULFILLMENT} />} />
          <Route exact path={link(ColCodes.SHOPIFY.ORDER_PAYMENT)} element={<Shopify columnCode={ColCodes.SHOPIFY.ORDER_PAYMENT} />} />
          <Route exact path={link(ColCodes.SHOPIFY.ORDER_UPDATE)} element={<Shopify columnCode={ColCodes.SHOPIFY.ORDER_UPDATE} />} />
          <Route exact path={link(ColCodes.SHOPIFY.REFUND_CREATE)} element={<Shopify columnCode={ColCodes.SHOPIFY.REFUND_CREATE} />} />

          <Route exact path={'show/art/:artId'} element={<ShowArt />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
