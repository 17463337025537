import React, { useState, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import '../../css/Dashboard.css'
import { auth } from '../../firebase'
import Navbar from '../Navbar'
import { ColumnCodes as ColCodes, ColumnLink as link } from '../../columns/Metadata'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { where } from 'firebase/firestore'
import { getWatercolorSettings } from '../../lib/watercolorSettings'
import CollectionChooser from '../CollectionChooser'
import { serverCall } from '../../lib/utils.js'

function Session () {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    sessionData: ''
  })
  const [validated, setValidated] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [selectedOrg, setSelectedOrg] = useState('')
  const [selectedOrgName, setSelectedOrgName] = useState('')
  const [selectedProfile, setSelectedProfile] = useState('')
  const [selectedProfileName, setSelectedProfileName] = useState('')
  const [selectedProfileImage, setSelectedProfileImage] = useState()

  const onOrgChange = ({ id, name }) => {
    setSelectedOrg(id)
    setSelectedOrgName(name)
  }

  const onProfileChange = ({ id, name, rest }) => {
    setSelectedProfile(id)
    setSelectedProfileName(name)
    setSelectedProfileImage(rest.image)
  }

  const updateInput = e => {
    const data = {
      ...formData,
      [e.target.id]: e.target.value
    }

    setFormData(data)
  }

  const clearForm = () => {
    setFormData({
      sessionData: ''
    })
    setValidated(false)
    setSelectedOrg('')
    setSelectedOrgName('')
    setSelectedProfile('')
    setSelectedProfileName('')
    setSelectedProfileImage('')
    setError('')
    setSuccess('')
  }

  const xmax = 1920
  const ymax = 1080

  const isNumberInRange = (x, y) => {
    const min = 0

    return (
      !isNaN(x) && !isNaN(y) &&
      x >= min && x <= xmax &&
      y >= min && y <= ymax
    )
  }

  const checkSessionData = (data) => {
    const lines = data.split('\n')
    if (lines.length < 2) {
      setError('Must have at least two data points.')
      return false
    }

    const errors = []
    lines.forEach((line, index) => {
      const pair = line.split(',')
      if (pair.length !== 2) {
        errors.push(index + 1)
        return
      }

      if (!isNumberInRange(pair[0], pair[1])) {
        errors.push(index + 1)
      }
    })

    if (errors.length) {
      setError(`Errors on line(s): ${errors.join(',')}`)
      return false
    }

    setError('')
    return true
  }

  const handleSubmit = async (event) => {
    setError('')
    setSuccess('')

    const form = event.currentTarget
    const formIsValid = form.checkValidity()

    if (!formIsValid) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
    }

    setValidated(true)
    if (!formIsValid) {
      return
    }

    if (!checkSessionData(formData.sessionData)) {
      return
    }

    try {
      const params = {
        canvas: [xmax, ymax],
        user: {
          uid: user.uid,
          email: user.email
        },
        profile: {
          image: selectedProfileImage,
          id: selectedProfile,
          name: selectedProfileName,
          org: {
            id: selectedOrg,
            name: selectedOrgName
          }
        },
        settings: getWatercolorSettings(),
        ...formData
      }

      const { data } = await serverCall({
        functionName: 'session-newSession',
        functionParams: params,
        preSubmit: () => setSubmitting(true)
      })

      if (data?.status === 'error') {
        throw new Error(data?.error)
      } else {
        setSuccess('Created a new session.')
        setTimeout(() => {
          setSuccess('')
        }, 3000)
      }
    } catch (e) {
      console.error(e)
      setError(e.toString())
      // don't clear the form on error
    }

    setValidated(false)
    // don't clear the form, so users can edit
    setSubmitting(false)
  }

  useEffect(() => {
    if (loading) {
      return
    }

    if (!user) {
      return navigate(link(ColCodes.HOME))
    }
  }, [user, loading, navigate])

  return (
    <>
      <Navbar title='New Session' />
      <div className='form-container'>

        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {success &&
            <Alert key="success" variant="success">
              {success}
            </Alert>
          }
        <CollectionChooser controlId={'orgChooser'} collectionName={'Org'} collectionNamePlural={'Orgs'} onChange={onOrgChange}
          disabled={submitting} value={selectedOrg} />
        <CollectionChooser controlId={'profileChooser'} collectionName={'Profile'} collectionNamePlural={'Profiles'} onChange={onProfileChange}
           disabled={submitting} value={selectedProfile} queryConstraints={selectedOrg ? [where('org', '==', selectedOrg)] : null}/>
        <Form.Group className="mb-3 form-item" controlId="sessionData">
          <Form.Label className="label-heading">Session Data</Form.Label>
          <Form.Label>One line per point - comma separated pair x,y. The maximum is {xmax},{ymax}.</Form.Label>
          <Form.Control disabled={submitting} required onChange={updateInput} as="textarea" rows={10} value={formData.sessionData}/>
          <Form.Control.Feedback type="invalid">
            Please add properly formatted session data.
          </Form.Control.Feedback>
          {error &&
            <Alert key="danger" variant="danger">
              {error}
            </Alert>
          }
        </Form.Group>
        {submitting &&
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        }

        <Button variant="secondary" onClick={clearForm} disabled={submitting}>Clear</Button>
        <Button variant="primary" type="submit" disabled={submitting}>Create New Session</Button>
      </Form>
    </div>
  </>
  )
}

export default Session
