import React from 'react'
import { formatDistanceToNow } from 'date-fns'
import {
  ColumnMetadataIndex as ColIndex,
  ColumnCodes as ColCodes
} from './Metadata'
import FirestoreImage from '../components/FirestoreImage'
import Spacer from '../components/Spacer'
import LoadingBootstrapIcon from '../components/LoadingBootstrapIcon'
import { checkAndExecuteFunction } from '../lib/utils'

export const NAME = ColCodes.ORG
export const TITLE = ColIndex[NAME].title

function relativeTimestampFormatter (cell, row) {
  const cellDate = cell.toDate()
  const relativeDate = formatDistanceToNow(cellDate, { addSuffix: true })

  return relativeDate
}

function getImage (cell, row, imageType) {
  const image = cell?.toString()
  const items = []

  if (image) {
    items.push(<FirestoreImage storagePath={cell.toString()} style={{ width: 50 }} />)
  } else {
    items.push(<span>(none)</span>)
  }

  items.push(<Spacer size={5} axis="horizontal" />)
  items.push(<LoadingBootstrapIcon iconName="Pencil" onClick={(done) => editImage(row.id, imageType, done) } title={'Edit'} className="actionButton" size={15} axis="horizontal" />)

  return items
}

function editImage (id, imageType, done) {
  alert(`TODO: edit ${imageType} image for org ${id}`)
  checkAndExecuteFunction(done)
}

function getAllArt (id, name, done) {
  window.open(`/sessions/org/${id}?name=${name}`, '_self')
  checkAndExecuteFunction(done)
}

function getAllProfiles (id, name, done) {
  window.open(`/profiles/${id}?name=${name}`, '_self')
  checkAndExecuteFunction(done)
}

export const EXPANDROW = {
  renderer: row => (
    <pre>{JSON.stringify(
      {
        id: row.id,
        image: row.image,
        watermark: row.watermark,
        timestamp: row.timestamp.toDate().toTimeString(),
        user: row.user
      },
      null,
      2)
      }</pre>
  ),
  showExpandColumn: true
}

export const COLUMNS = [
  {
    dataField: 'id',
    text: 'Id',
    editable: false,
    hidden: true
  },
  {
    dataField: 'name',
    text: 'Name',
    editable: false
  },
  {
    dataField: 'image',
    text: 'image',
    editable: false,
    formatter: (cell, row) => getImage(cell, row, 'org'),
    events: {
      onClick: e => e.stopPropagation()
    }
  },
  {
    dataField: 'watermark',
    text: 'watermark',
    editable: false,
    formatter: (cell, row) => getImage(cell, row, 'watermark'),
    events: {
      onClick: e => e.stopPropagation()
    }
  },
  {
    dataField: 'parentOrg',
    text: 'parentOrg',
    editable: false
  },
  {
    dataField: 'timestamp',
    text: 'timestamp (UTC)',
    title: (cell) => cell.toDate().toISOString(),
    formatter: relativeTimestampFormatter,
    editable: false
  },
  {
    dataField: 'action',
    isDummyField: true,
    text: 'action',
    editable: false,
    formatter: (cellContent, row) => {
      const spacerSize = 5
      const buttonSize = 25

      const items = []
      items.push(<LoadingBootstrapIcon iconName="PeopleFill" onClick={(done) => getAllProfiles(row.id, row.name, done)} title={`${row.name}'s members`} className="actionButton" size={buttonSize} axis="horizontal" />)
      items.push(<Spacer size={spacerSize} axis="horizontal" />)

      items.push(<LoadingBootstrapIcon iconName="Images" onClick={(done) => getAllArt(row.id, row.name, done)} title={`${row.name}'s art`} className="actionButton" size={buttonSize} axis="horizontal" />)
      items.push(<Spacer size={spacerSize} axis="horizontal" />)

      return items
    },
    events: {
      onClick: e => e.stopPropagation()
    }
  }

]
