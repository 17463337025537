export const ColumnCodes = {
  UNAUTHORIZED_ACCESS: 'Unauthorized Access',
  HOME: 'Home',
  SUMMARY: 'Summary',
  UNAUTHORIZED: 'Unauthorized',
  ADMIN: 'Admin',
  COMPLETED: 'Completed',
  APIKEY: 'APIKey',
  ARCHIVED: 'Archived',
  CANVAS: 'Canvas',
  PROFILE: 'Profile',
  ORG: 'Org',
  SESSION: 'Session',
  WATERMARK: 'Watermark',
  SHOPIFY: {
    ORDER_CANCEL: 'ShopifyOrderCancel',
    ORDER_CREATE: 'ShopifyOrderCreate',
    ORDER_DELETE: 'ShopifyOrderDelete',
    ORDER_EDIT: 'ShopifyOrderEdit',
    ORDER_FULFILLMENT: 'ShopifyOrderFulfillment',
    ORDER_PAYMENT: 'ShopifyOrderPayment',
    ORDER_UPDATE: 'ShopifyOrderUpdate',
    REFUND_CREATE: 'ShopifyRefundCreate',
    EXPORT_CSV: 'ShopifyExportCsv'
  }
}
const SHOPIFY_BACKGROUND_COLOR = '#95bf47'
const SHOPIFY_EXPORT_CSV_COLOR = '#329ba8'

export const ColumnMetadataList = [
  { code: ColumnCodes.UNAUTHORIZED_ACCESS, link: '/unauthorized-access', title: 'Unauthorized Access', text: 'Unauthorized Access (Logged)', hidden: true },
  { code: ColumnCodes.HOME, link: '/', title: 'Home', text: 'GlassCube.tech Home Page', hidden: true },
  { code: ColumnCodes.SUMMARY, link: '/dashboard', title: 'Summary', text: 'The GlassCube.tech Dashboard', hidden: true },
  { code: ColumnCodes.UNAUTHORIZED, link: '/unauthorized-users', title: 'Unauthorized Users', text: 'The number of unauthorized users that tried to sign in.', color: 'red' },
  { code: ColumnCodes.ADMIN, link: '/admin-users', title: 'Admin Users', text: 'The number of Admin users.', color: 'blue' },
  { code: ColumnCodes.COMPLETED, link: '/completed', title: 'Completed Art', text: 'The number of art that has been completed.', color: 'green' },
  { code: ColumnCodes.APIKEY, link: '/apikeys', title: 'API Keys', text: 'The number of API Keys in use.' },
  { code: ColumnCodes.ARCHIVED, link: '/archived', title: 'Archived Art', text: 'The number of art that has been archived.' },
  { code: ColumnCodes.CANVAS, link: '/canvas', title: 'Canvas Types', text: 'The number of canvas types in use.' },
  { code: ColumnCodes.PROFILE, link: '/profiles', title: 'Artist Profiles', text: 'The number of artist profiles stored.' },
  { code: ColumnCodes.ORG, link: '/orgs', title: 'Artist Orgs', text: 'The number of artist orgs stored.' },
  { code: ColumnCodes.SESSION, link: '/sessions', title: 'Artist Sessions', text: 'The number of artist sessions stored.' },
  { code: ColumnCodes.WATERMARK, link: '/watermarks', title: 'Watermark Types', text: 'The number of watermark types in use.' },
  { code: ColumnCodes.SHOPIFY.EXPORT_CSV, link: '/export-csv', backgroundColor: SHOPIFY_EXPORT_CSV_COLOR, title: 'Shopify Export CSV', text: 'Export sessions as CSV for Shopify' },
  { code: ColumnCodes.SHOPIFY.ORDER_CANCEL, backgroundColor: SHOPIFY_BACKGROUND_COLOR, link: '/orders-cancel', title: 'Cancelled Shopify Orders', text: 'The number of cancelled Shopify orders.' },
  { code: ColumnCodes.SHOPIFY.ORDER_CREATE, backgroundColor: SHOPIFY_BACKGROUND_COLOR, link: '/orders-create', title: 'Created Shopify Orders', text: 'The number of created Shopify orders.' },
  { code: ColumnCodes.SHOPIFY.ORDER_DELETE, backgroundColor: SHOPIFY_BACKGROUND_COLOR, link: '/orders-delete', title: 'Deleted Shopify Orders', text: 'The number of deleted Shopify orders.' },
  { code: ColumnCodes.SHOPIFY.ORDER_EDIT, backgroundColor: SHOPIFY_BACKGROUND_COLOR, link: '/orders-edit', title: 'Edited Shopify Orders', text: 'The number of edited Shopify orders.' },
  { code: ColumnCodes.SHOPIFY.ORDER_FULFILLMENT, backgroundColor: SHOPIFY_BACKGROUND_COLOR, link: '/orders-fulfillment', title: 'Fulfilled Shopify Orders', text: 'The number of fulfilled Shopify orders.' },
  { code: ColumnCodes.SHOPIFY.ORDER_PAYMENT, backgroundColor: SHOPIFY_BACKGROUND_COLOR, link: '/orders-payment', title: 'Payments for Shopify Orders', text: 'The number of payments for Shopify orders.' },
  { code: ColumnCodes.SHOPIFY.ORDER_UPDATE, backgroundColor: SHOPIFY_BACKGROUND_COLOR, link: '/orders-update', title: 'Updated Shopify Orders', text: 'The number of updated Shopify orders.' },
  { code: ColumnCodes.SHOPIFY.REFUND_CREATE, backgroundColor: SHOPIFY_BACKGROUND_COLOR, link: '/refund-create', title: 'Refunded Shopify Orders', text: 'The number of refunded Shopify orders.' }
]

export const ColumnMetadataIndex = ColumnMetadataList.reduce((acc, obj) => {
  acc[obj.code] = obj
  return acc
}, {})

export const ColumnLink = (code) => ColumnMetadataIndex[code].link
export const ColumnTitle = (code) => ColumnMetadataIndex[code].title
