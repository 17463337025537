import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import './UnauthorizedAccess.css'

function UnauthorizedAccess () {
  const { state } = useLocation()

  return (
    <div>
      <h1>
      Unauthorized Access
      </h1>
      <div>
        {state.message}
      </div>
      <Link to="/">Go Home</Link>
    </div>

  )
}
export default UnauthorizedAccess
