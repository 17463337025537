import React from 'react'
import { formatDistanceToNow } from 'date-fns'
import {
  ColumnMetadataIndex as ColIndex,
  ColumnCodes as ColCodes
} from './Metadata'
import FirestoreImage from '../components/FirestoreImage'
import Spacer from '../components/Spacer'
import LoadingBootstrapIcon from '../components/LoadingBootstrapIcon'
import { checkAndExecuteFunction } from '../lib/utils'

export const NAME = ColCodes.PROFILE
export const TITLE = ColIndex[NAME].title

function relativeTimestampFormatter (cell, row) {
  const cellDate = cell.toDate()
  const relativeDate = formatDistanceToNow(cellDate, { addSuffix: true })

  return relativeDate
}

function getImage (cell, row) {
  const storagePath = cell.toString()
  return <FirestoreImage storagePath={storagePath} style={{ width: 50 }} />
}

function getAllArt (id, name, done) {
  window.open(`/sessions/profile/${id}?name=${name}`, '_self')
  checkAndExecuteFunction(done)
}

export const EXPANDROW = {
  renderer: row => (
    <pre>{JSON.stringify(
      {
        id: row.id,
        image: row.image,
        org: {
          id: row.org,
          name: row.orgName
        },
        user: row.user,
        timestamp: row.timestamp.toDate().toTimeString()
      },
      null,
      2)
      }</pre>
  ),
  showExpandColumn: true
}

export const COLUMNS = [
  {
    dataField: 'id',
    text: 'id',
    editable: false,
    hidden: true
  },
  {
    dataField: 'name',
    text: 'name',
    editable: false
  },
  {
    dataField: 'image',
    text: 'image',
    editable: false,
    formatter: getImage
  },
  {
    dataField: 'orgName',
    text: 'orgName',
    editable: false
  },
  {
    dataField: 'timestamp',
    text: 'timestamp (UTC)',
    title: (cell) => cell.toDate().toISOString(),
    formatter: relativeTimestampFormatter,
    editable: false
  },
  {
    dataField: 'action',
    isDummyField: true,
    text: 'action',
    editable: false,
    formatter: (cellContent, row) => {
      const spacerSize = 5
      const buttonSize = 25

      const items = []
      items.push(<LoadingBootstrapIcon iconName="Images" onClick={(done) => getAllArt(row.id, row.name, done)} title={`${row.name}'s art`} className="actionButton" size={buttonSize} axis="horizontal" />)
      items.push(<Spacer size={spacerSize} axis="horizontal" />)

      return items
    },
    events: {
      onClick: e => e.stopPropagation()
    }
  }
]
