import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import '../css/Dashboard.css'
import { format } from 'date-fns'
import { serverCall } from '../lib/utils.js'

function ShowArt () {
  const { artId } = useParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [art, setArt] = useState(null)

  useEffect(() => {
    setLoading(true)
    async function getArt () {
      const { data } = await serverCall({
        functionName: 'profile-getArt',
        functionParams: {
          artId
        }
      })
      if (data?.status === 'error') {
        setError(data?.error)
        setLoading(false)
      } else {
        setLoading(false)
        setArt(data)
      }
    }

    getArt()
  }, [artId])

  return (
    <>
    {loading && <div>Loading...</div>}
    {!loading && art && <div>
      <a href={art.profileImageUrl}>
        <img src={art.profileThumbImageUrl} />
      </a>
      <div>{art.name} / {art.org}</div>
      <div>
        <img src={art.watermarkedThumbImageUrl} />
      </div>
      <div>
        {format(new Date(art.timestamp._seconds * 1000), 'PPPP pppp')}
      </div>
    </div>}
    {error && <div>{error}</div>}
    </>
  )
}
export default ShowArt
