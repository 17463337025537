import React, { useState, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import '../../css/Dashboard.css'
import { auth } from '../../firebase'
import Navbar from '../Navbar'
import { ColumnCodes as ColCodes, ColumnLink as link } from '../../columns/Metadata'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { getStorage, ref, uploadString } from 'firebase/storage'
import Alert from 'react-bootstrap/Alert'
import getCroppedImg from '../../lib/cropImage'
import ImagePicker from '../ImagePicker'
import ImagePreview from '../ImagePreview'
import { serverCall } from '../../lib/utils.js'
import Spacer from '../Spacer'

function Org () {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [validated, setValidated] = useState(false)
  const [orgName, setOrgName] = useState('')
  const [orgImagePreviewUrl, setOrgImagePreviewUrl] = useState()
  const [orgWatermarkDataUrl, setOrgWatermarkDataUrl] = useState()
  const [orgImageCroppedAreaPixels, setOrgImageCroppedAreaPixels] = useState()

  const onOrgImageChange = ({ previewImageUrl, croppedAreaPixels }) => {
    setOrgImagePreviewUrl(previewImageUrl)
    setOrgImageCroppedAreaPixels(croppedAreaPixels)
  }

  const onOrgWatermarkChange = (dataUrl) => {
    setOrgWatermarkDataUrl(dataUrl)
  }

  const handleSubmit = async (event) => {
    setError('')
    setSuccess('')

    const form = event.currentTarget
    const formIsValid = form.checkValidity()

    if (!formIsValid) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
    }

    setValidated(true)
    if (!formIsValid) {
      return
    }

    try {
      setSubmitting(true)

      const croppedOrgImage = await getCroppedImg({
        imageSrc: orgImagePreviewUrl,
        pixelCrop: orgImageCroppedAreaPixels,
        dataUrl: true
      })

      const currentUser = {
        uid: user.uid,
        email: user.email
      }

      const { data: newOrgData } = await serverCall({
        functionName: 'org-newOrg',
        functionParams: {
          user: currentUser,
          name: orgName
        }
      })

      if (newOrgData?.status === 'error') {
        throw new Error(newOrgData?.error)
      } else {
        setSuccess('Created a new Org.')
      }

      const storage = getStorage()
      let storageRef

      const orgImageFilePath = `orgs/${newOrgData?.id}.jpg`
      storageRef = ref(storage, orgImageFilePath)
      // 'file' comes from the Blob or File API
      await uploadString(storageRef, croppedOrgImage, 'data_url')
      setSuccess('Uploading the Org image...')

      const orgWatermarkFilePath = `orgs/watermarks/${newOrgData?.id}.png`
      storageRef = ref(storage, orgWatermarkFilePath)
      await uploadString(storageRef, orgWatermarkDataUrl, 'data_url')
      setSuccess('Uploading the Org Watermark...')

      const { data: updateOrgImageData } = await serverCall({
        functionName: 'org-updateOrgImages',
        functionParams: {
          id: newOrgData?.id,
          image: orgImageFilePath,
          watermark: orgWatermarkFilePath,
          user: currentUser
        }
      })

      if (updateOrgImageData?.status === 'error') {
        throw new Error(updateOrgImageData?.error)
      } else {
        setSuccess('Org image(s) uploaded.')
        setTimeout(() => {
          setSuccess('')
        }, 3000)
      }

      setValidated(false)
      setOrgName('')
    } catch (e) {
      console.error(e)
      setError(e.toString())
      // don't clear the form on error
    }

    setSubmitting(false)
  }

  useEffect(() => {
    if (loading) {
      return
    }

    if (!user) {
      return navigate(link(ColCodes.HOME))
    }
  }, [user, loading, navigate])

  return (
    <>
      <Navbar title='New Org' />
      <div className='form-container'>
        {success &&
          <Alert key="success" variant="success">
            {success}
          </Alert>
        }
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3 form-item" controlId="orgName">
              <Form.Label className="label-heading">Org Name</Form.Label>
              <Form.Control required disabled={submitting} onChange={(e) => setOrgName(e.target.value)} type="text" placeholder="My Org" value={orgName}/>
              <Form.Control.Feedback type="invalid">
                Please add an Org name (at least 4 characters).
              </Form.Control.Feedback>
            </Form.Group>
            <ImagePicker label='Org Image' aspect={1} controlId={'orgImagePicker'} disabled={submitting} onChange={onOrgImageChange} />
            <ImagePreview label='Watermark' controlId={'orgWatermarkPicker'} disabled={submitting} onPreviewSrc={onOrgWatermarkChange} />
            {error &&
              <Alert key="danger" variant="danger">
                {error}
              </Alert>
            }
            {submitting &&
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            }
            <Spacer size={50} axis="vertical" />
            <Button disabled={submitting} variant="primary" type="submit">Create New Org</Button>
        </Form>
      </div>
    </>
  )
}
export default Org
