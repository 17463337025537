import React, { useState, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import '../../css/Dashboard.css'
import { auth } from '../../firebase'
import Navbar from '../Navbar'
import { ColumnCodes as ColCodes, ColumnLink as link } from '../../columns/Metadata'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { getStorage, ref, uploadString } from 'firebase/storage'
import Alert from 'react-bootstrap/Alert'
import getCroppedImg from '../../lib/cropImage'
import CollectionChooser from '../CollectionChooser'
import ImagePicker from '../ImagePicker'
import { serverCall } from '../../lib/utils.js'

function Profile () {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const [validated, setValidated] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [previewImageUrl, setPreviewImageUrl] = useState()
  const [croppedAreaPixels, setCroppedAreaPixels] = useState()
  const [selectedOrg, setSelectedOrg] = useState('')
  const [selectedOrgName, setSelectedOrgName] = useState('')
  const [profileName, setProfileName] = useState('')

  const onOrgChange = ({ id, name }) => {
    setSelectedOrg(id)
    setSelectedOrgName(name)
  }

  const onImageChange = ({ previewImageUrl, croppedAreaPixels }) => {
    setPreviewImageUrl(previewImageUrl)
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const handleSubmit = async (event) => {
    setError('')
    setSuccess('')

    const form = event.currentTarget
    const formIsValid = form.checkValidity()

    if (!formIsValid) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
    }

    setValidated(true)
    if (!formIsValid) {
      return
    }

    try {
      setSubmitting(true)
      const croppedImage = await getCroppedImg({
        imageSrc: previewImageUrl,
        pixelCrop: croppedAreaPixels,
        dataUrl: true
      })

      const currentUser = {
        uid: user.uid,
        email: user.email
      }

      const { data: newProfileData } = await serverCall({
        functionName: 'profile-newProfile',
        functionParams: {
          user: {
            uid: user.uid,
            email: user.email
          },
          name: profileName,
          org: selectedOrg,
          orgName: selectedOrgName
        }
      })

      if (newProfileData?.status === 'error') {
        throw new Error(newProfileData?.error)
      } else {
        setSuccess('Created a new Profile.')
      }

      const storage = getStorage()
      const profileImageFilePath = `profiles/${newProfileData?.id}.jpg`
      const storageRef = ref(storage, profileImageFilePath)

      // 'file' comes from the Blob or File API
      await uploadString(storageRef, croppedImage, 'data_url')
      setSuccess('Uploading the Profile image...')

      const { data: updateProfileImageData } = await serverCall({
        functionName: 'profile-updateProfileImage',
        functionParams: {
          id: newProfileData?.id,
          image: profileImageFilePath,
          user: currentUser
        }
      })

      if (updateProfileImageData?.status === 'error') {
        throw new Error(updateProfileImageData?.error)
      } else {
        setSuccess('Profile image uploaded.')
        setTimeout(() => {
          setSuccess('')
        }, 3000)
      }

      setValidated(false)
    } catch (e) {
      console.error(e)
      setError(e.toString())
      // don't clear the form on error
    }

    setSubmitting(false)
  }

  useEffect(() => {
    if (loading) {
      return
    }

    if (!user) {
      return navigate(link(ColCodes.HOME))
    }
  }, [user, loading, navigate])

  return (
    <>
      <Navbar title='New Profile' />
      <div className='form-container'>
        {success &&
          <Alert key="success" variant="success">
            {success}
          </Alert>
        }
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3 form-item" controlId="profileName">
            <Form.Label className="label-heading">Profile Name</Form.Label>
            <Form.Control disabled={submitting} required minLength="4" onChange={(e) => setProfileName(e.target.value)} type="text" placeholder="John Doe" value={profileName}/>
            <Form.Control.Feedback type="invalid">
                Please add a Profile name (at least 4 characters).
            </Form.Control.Feedback>
          </Form.Group>
          <CollectionChooser controlId={'orgChooser'} collectionName={'Org'}
            collectionNamePlural={'Orgs'} onChange={onOrgChange} disabled={submitting} />
          <ImagePicker controlId={'imagePicker'} disabled={submitting} onChange={onImageChange} />
          {error &&
            <Alert key="danger" variant="danger">
              {error}
            </Alert>
          }
          {submitting &&
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            }
          <Button disabled={submitting} variant="primary" type="submit">Create New Profile</Button>
        </Form>
      </div>
    </>
  )
}
export default Profile
