import React, { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useParams, useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import '../css/Dashboard.css'
import { auth } from '../firebase'
import Navbar from './Navbar'
import DataTable from './DataTable'
import Spinner from 'react-bootstrap/Spinner'
import { COLUMNS, NAME, TITLE, EXPANDROW } from '../columns/Sessions'
import { ColumnCodes as ColCodes, ColumnLink as link } from '../columns/Metadata'
import { orderBy, where } from 'firebase/firestore'
import Button from 'react-bootstrap/Button'

function Sessions () {
  const { filter, id } = useParams()
  const [searchParams] = useSearchParams()
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const location = useLocation()
  const newSession = `${location.pathname}/new`
  const whiteBoard = `${location.pathname}/whiteboard`

  useEffect(() => {
    if (loading) {
      return
    }

    if (!user) {
      return navigate(link(ColCodes.HOME))
    }
  }, [user, loading, navigate])

  const queryConstraints = []
  const allowedFilters = {
    profile: 'profile.id',
    org: 'profile.org.id'
  }

  if (filter && id) {
    if (Object.keys(allowedFilters).includes(filter.toLowerCase())) {
      queryConstraints.push(where(allowedFilters[filter], '==', id))
    }
  }

  return (
    <>
      <Navbar title={filter ? `${TITLE} (${filter}:${searchParams.get('name')})` : TITLE }/>
      {loading &&
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      }
      {!loading &&
        <>
          <Button href={newSession}>New Session</Button>
          <Button href={whiteBoard}>Whiteboard</Button>
          <DataTable
            isFilteredQuery={queryConstraints.length > 0}
            collectionName={NAME}
            columns={COLUMNS}
            expandRow={EXPANDROW}
            querySort={[orderBy('timestamp', 'desc')]}
            queryConstraints={queryConstraints}
          />
        </>
      }
    </>
  )
}
export default Sessions
