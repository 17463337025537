import React, { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import '../css/Dashboard.css'
import { auth } from '../firebase'
import Navbar from './Navbar'
import DataTable from './DataTable'
import Spinner from 'react-bootstrap/Spinner'
import { COLUMNS, NAME, EXPANDROW } from '../columns/Sessions'
import { ColumnMetadataIndex as ColIndex, ColumnCodes as ColCodes, ColumnLink as link } from '../columns/Metadata'
import { orderBy, where } from 'firebase/firestore'

// override
export const TITLE = ColIndex[ColCodes.COMPLETED].title

function Completed () {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  useEffect(() => {
    if (loading) {
      return
    }

    if (!user) {
      return navigate(link(ColCodes.HOME))
    }
  }, [user, loading, navigate])

  return (
    <>
      <Navbar title={TITLE} />
      {loading &&
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      }
      {!loading &&
        <DataTable
          collectionName={NAME}
          statsName={'Completed'}
          columns={COLUMNS}
          expandRow={EXPANDROW}
          querySort={[orderBy('timestamp', 'desc')]}
          queryConstraints={[where('status', '==', 'generate:completed')]}
        />
      }
    </>
  )
}
export default Completed
