import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { storage } from '../firebase'
import { ref as storageRef, getDownloadURL } from 'firebase/storage'
import Spinner from 'react-bootstrap/Spinner'
import { getThumbnailPath } from '../lib/utils'

export default function FirestoreImage ({ storagePath, thumbnailPath, style }) {
  const [urlValue, setUrlValue] = useState('')
  const [urlError, setUrlError] = useState('')
  const [urlLoading, setUrlLoading] = useState(false)
  const [linkValue, setLinkValue] = useState('')

  if (!thumbnailPath) {
    thumbnailPath = getThumbnailPath(storagePath)
  }

  const placeholderImage = '/images/default.png'
  const onImageError = (e) => {
    console.error('FirestoreImage Error:', e.target.src)
    e.target.src = placeholderImage
  }

  const delayedLoading = (storeRef, ms) => {
    setTimeout(() => {
      setUrlLoading(true)
      getDownloadURL(storeRef)
        .then(setUrlValue)
        .catch(setUrlError)
        .finally(() => setUrlLoading(false))
    }, ms)
  }

  useEffect(() => {
    if (!storagePath) {
      setUrlValue('')
      setUrlError('')
      setUrlLoading(false)
      return
    }

    const storeLinkRef = storageRef(storage, storagePath)
    const storeThumbnailRef = storageRef(storage, thumbnailPath)

    setUrlLoading(true)
    getDownloadURL(storeThumbnailRef)
      .then(setUrlValue)
      .catch((e) => {
        setUrlValue('/images/default.png')
        delayedLoading(storeThumbnailRef, 5000)
      })
      .finally(() => setUrlLoading(false))
    getDownloadURL(storeLinkRef)
      .then((value) => {
        setLinkValue(value)
      })
      .catch(setUrlError)
  }, [storagePath, thumbnailPath])

  return (
    <>
      {urlError && <div>{urlError}</div>}
      {urlValue &&
        <a href={linkValue} target='_blank' rel="noreferrer">
          <img
            src={urlValue}
            onLoad={() => setUrlLoading(false)}
            style={style}
            onError={onImageError}
            />
        </a>
      }
      {urlLoading &&
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
    }
    </>
  )
}

FirestoreImage.propTypes = {
  storagePath: PropTypes.string,
  thumbnailPath: PropTypes.string,
  style: PropTypes.object
}
