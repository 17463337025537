import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import '../css/Dashboard.css'

export default function ImagePreview (props) {
  const { label = 'Image', controlId, disabled, width = '500px', onPreviewSrc } = props
  const [imageFile, setImageFile] = useState(null)
  const [previewSrc, setPreviewSrc] = useState('')

  useEffect(() => {
    if (!imageFile) {
      return
    }

    const reader = new FileReader()
    reader.onloadend = () => {
      setPreviewSrc(reader.result)
      if (typeof onPreviewSrc === 'function') {
        onPreviewSrc(reader.result)
      }
    }
    reader.readAsDataURL(imageFile)

    return () => {
      reader.abort()
    }
  }, [imageFile])

  const handleChange = (e) => {
    const files = e.target.files
    if (!files || !files.item(0)) {
      return
    }
    setImageFile(files.item(0))
  }

  return (
    <div style={{ width }}>
      {/* <input disabled={disabled} type='file' accept='image/*' aria-label='Upload image' onChange={handleChange} /> */}
      <Form.Group className="mb-3 form-item" controlId={controlId}>
        <Form.Label className="label-heading">{label}</Form.Label>
        <Form.Control required disabled={disabled} onChange={handleChange} type="file"/>
        <Form.Control.Feedback type="invalid">
            Please choose an image.
        </Form.Control.Feedback>
      </Form.Group>
      <div style={{ backgroundColor: 'silver', overflow: 'hidden' }}>
      {imageFile && (
          <img
            src={previewSrc}
            alt='preview of image'
            width='100%'
            height='100%'
            style={{ objectFit: 'contain' }}
          />
      )}
      </div>
    </div>
  )
}

ImagePreview.propTypes = {
  controlId: PropTypes.string,
  width: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onPreviewSrc: PropTypes.func
}
