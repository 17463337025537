import React, { useEffect, useState } from 'react'
import '../css/Dashboard.css'
import { db } from '../firebase'
import { getCountFromServer, limitToLast, endBefore, startAfter, limit, collection, query, onSnapshot } from 'firebase/firestore'
import BootstrapTable from 'react-bootstrap-table-next'
import Spinner from 'react-bootstrap/Spinner'
import PropTypes from 'prop-types'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator'
import { getDatabase, onValue, ref as dbRef } from 'firebase/database'
import PrevNextPagination from './PrevNextPagination'

const RemotePagination = ({
  totalSize,
  data,
  columns,
  page,
  sizePerPage,
  noDataIndication,
  expandRow,
  prevPage,
  nextPage
}) => (
  <div>
    <PaginationProvider
      pagination={
        paginationFactory({
          custom: true,
          page,
          sizePerPage
        })
      }
    >
      {
        ({
          paginationProps,
          paginationTableProps
        }) => (
          <>
             <div>
              { false &&
              <PaginationListStandalone
                { ...paginationProps }
              />
              }
              <PrevNextPagination totalSize={totalSize} sizePerPage={sizePerPage} prevPage={prevPage} nextPage={nextPage} { ...paginationProps } />
            </div>
            <BootstrapTable
              remote
              striped
              hover
              condensed
              keyField='id'
              data={ data }
              columns={ columns }
              noDataIndication={noDataIndication}
              expandRow = {expandRow}
              { ...paginationTableProps }
            />
          </>
        )
      }
    </PaginationProvider>
  </div>
)

RemotePagination.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  page: PropTypes.number,
  sizePerPage: PropTypes.number,
  totalSize: PropTypes.number,
  noDataIndication: PropTypes.func,
  expandRow: PropTypes.object,
  onTableChange: PropTypes.func,
  prevPage: PropTypes.func,
  nextPage: PropTypes.func
}

function DataTable (props) {
  const { isFilteredQuery, queryConstraints: propQueryConstraints = [], querySort = [] } = props
  const [tableData, setTableData] = useState([])
  const [page, setPage] = useState(1)
  const [totalSize, setTotalSize] = useState(0)
  const [sizePerPage/*, setSizePerPage */] = useState(20)
  const [dataFetched, setDataFetched] = useState(false)
  const [firstDoc, setFirstDoc] = useState() // for Firestore pagination
  const [lastDoc, setLastDoc] = useState() // for Firestore pagination
  const [queryConstraints, setQueryConstraints] = useState([...propQueryConstraints])

  // TODO: need to unsubscribe from return value of each call
  async function fetchCollection (constraints = []) {
    setDataFetched(false)

    if (isFilteredQuery && totalSize === 0) {
      await getQueryCount([...querySort, ...propQueryConstraints])
    }

    const q = query(collection(db, props.collectionName), ...constraints)
    // return the unsubscribe function
    return onSnapshot(q, (querySnapshot) => {
      setFirstDoc(querySnapshot.docs[0])
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1])
      setTableData(querySnapshot.docs.map((doc) => {
        const data = doc.data()
        // conflict, we rename the column
        if (data.id) {
          data.dataId = data.id
        }
        return { ...data, id: doc.id }
      }))
      setDataFetched(true)
    })
  }

  const nextPage = () => {
    setPage(page + 1)
    setQueryConstraints([
      limit(sizePerPage),
      startAfter(lastDoc)
    ])
  }

  const prevPage = () => {
    setPage(page - 1)
    setQueryConstraints([
      limitToLast(sizePerPage),
      endBefore(firstDoc)
    ])
  }

  const getQueryCount = async (constraints) => {
    const q = query(collection(db, props.collectionName), ...constraints)
    const countSnapshot = await getCountFromServer(q)
    setTotalSize(countSnapshot.data().count)
  }

  useEffect(() => {
    const database = getDatabase()
    const reference = dbRef(database, 'Stats')
    onValue(reference, (snapshot) => {
      const data = snapshot.val()
      if (!isFilteredQuery) {
        const totalSize = data[props.statsName || props.collectionName]
        setTotalSize(totalSize)
      }
    })

    // return the cleanup function
    return fetchCollection([
      limit(sizePerPage),
      ...querySort,
      ...queryConstraints
    ])
  }, [queryConstraints, page])

  const handleLoading = () => {
    if (!dataFetched) {
      return (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )
    } else {
      return 'no data'
    }
  }

  return (
    <RemotePagination
      data={ tableData }
      columns={ props.columns }
      noDataIndication={handleLoading}
      expandRow = {props.expandRow}
      totalSize={totalSize}
      sizePerPage={sizePerPage}
      page={page}
      prevPage={prevPage}
      nextPage={nextPage}
    />
  )
}

DataTable.propTypes = {
  isFilteredQuery: PropTypes.bool,
  queryConstraints: PropTypes.array,
  columns: PropTypes.array,
  expandRow: PropTypes.object,
  collectionName: PropTypes.string,
  statsName: PropTypes.string,
  querySort: PropTypes.array
}

export default DataTable
