import React, { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useSearchParams, useParams, useNavigate, useLocation } from 'react-router-dom'
import '../css/Dashboard.css'
import { auth } from '../firebase'
import Navbar from './Navbar'
import DataTable from './DataTable'
import Spinner from 'react-bootstrap/Spinner'
import { COLUMNS, NAME, TITLE, EXPANDROW } from '../columns/Profiles'
import { ColumnCodes as ColCodes, ColumnLink as link } from '../columns/Metadata'
import { orderBy, where } from 'firebase/firestore'
import Button from 'react-bootstrap/Button'

function Profiles () {
  const { orgId } = useParams()
  const [searchParams] = useSearchParams()
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const location = useLocation()
  const newProfile = `${location.pathname}/new`

  useEffect(() => {
    if (loading) {
      return
    }

    if (!user) {
      return navigate(link(ColCodes.HOME))
    }
  }, [user, loading, navigate])

  const queryConstraints = []
  if (orgId) {
    queryConstraints.push(where('org', '==', orgId))
  }

  return (
    <>
      <Navbar title={orgId ? `${TITLE} (org:${searchParams.get('name')})` : TITLE }/>
      {loading &&
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      }
      {!loading &&
        <>
          <Button href={newProfile}>New Profile</Button>
          <DataTable
            isFilteredQuery={queryConstraints.length > 0}
            collectionName={NAME}
            columns={COLUMNS}
            expandRow={EXPANDROW}
            querySort={[orderBy('timestamp', 'desc')]}
            queryConstraints={queryConstraints}
          />
        </>
      }
    </>
  )
}
export default Profiles
