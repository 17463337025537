import { formatDistanceToNow } from 'date-fns'
import {
  ColumnMetadataIndex as ColIndex,
  ColumnCodes as ColCodes
} from './Metadata'

export const NAME = ColCodes.ADMIN
export const TITLE = ColIndex[NAME].title

function relativeTimestampFormatter (cell, row) {
  const cellDate = cell.toDate()
  const relativeDate = formatDistanceToNow(cellDate, { addSuffix: true })

  return relativeDate
}

export const COLUMNS = [
  {
    dataField: 'id',
    text: 'id',
    editable: false
  },
  {
    dataField: 'email',
    text: 'email',
    editable: false
  },
  {
    dataField: 'timestamp',
    text: 'timestamp',
    title: (cell) => cell.toDate().toISOString(),
    formatter: relativeTimestampFormatter,
    editable: false
  }

]
