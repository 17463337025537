import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'

function LoadingButton (props) {
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (isLoading) {
      const done = () => setLoading(false)
      props.handleClick && props.handleClick(done)
    }
  }, [isLoading])

  const handleClick = () => setLoading(true)

  return (
    <Button
      variant={props.variant || 'primary'}
      size={props.size || 'sm'}
      disabled={isLoading}
      onClick={!isLoading ? handleClick : null}
    >
      {isLoading ? 'working…' : props.text}
    </Button>
  )
}

LoadingButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string,
  handleClick: PropTypes.func
}

export default LoadingButton
