import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth, signInWithGoogle } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import '../css/Login.css'
import Button from 'react-bootstrap/Button'
import {
  ColumnCodes as ColCodes,
  ColumnLink as link
} from '../columns/Metadata'

function Login () {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }
    if (user) {
      navigate(link(ColCodes.SUMMARY))
    }
  }, [user, loading, navigate])

  return (
    <div className="login">
        <Button variant="success" onClick={signInWithGoogle}>Sign in with Google</Button>
    </div>
  )
}
export default Login
