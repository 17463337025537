import React from 'react'
import { formatDistanceToNow } from 'date-fns'
import {
  ColumnMetadataIndex as ColIndex,
  ColumnCodes as ColCodes
} from './Metadata'

export const NAME = ColCodes.CANVAS
export const TITLE = ColIndex[NAME].title

function relativeTimestampFormatter (cell, row) {
  const cellDate = cell.toDate()
  const relativeDate = formatDistanceToNow(cellDate, { addSuffix: true })

  return relativeDate
}

export const EXPANDROW = {
  renderer: row => (
    <pre>{JSON.stringify(
      { timestamp: row.timestamp.toDate().toTimeString(), ...row.data },
      null,
      2)
      }</pre>
  ),
  showExpandColumn: true
}

export const COLUMNS = [
  {
    dataField: 'id',
    text: 'id',
    editable: false,
    hidden: true
  },
  {
    dataField: 'data',
    text: 'data',
    editable: false,
    hidden: true
  },
  {
    dataField: 'label',
    text: 'label',
    editable: false
  },
  {
    dataField: 'status',
    text: 'status',
    editable: false
  },
  {
    dataField: 'timestamp',
    text: 'timestamp (UTC)',
    title: (cell) => cell.toDate().toISOString(),
    formatter: relativeTimestampFormatter,
    editable: false
  }
]
