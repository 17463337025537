import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-bootstrap/Spinner'
import * as icons from 'react-bootstrap-icons'

function LoadingBootstrapIcon (props) {
  const { iconName, smallSpinner = true } = props
  const [isLoading, setLoading] = useState(false)
  const BootstrapIcon = icons[iconName]

  useEffect(() => {
    if (isLoading) {
      const done = () => setLoading(false)
      props.onClick && props.onClick(done)
    }
  }, [isLoading])

  const onClick = () => setLoading(true)

  return (
    <>
    {isLoading &&
      <Spinner size={smallSpinner ? 'sm' : 'lg'} animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    }
    {!isLoading &&
      <BootstrapIcon
        onClick={!isLoading ? onClick : null}
        className={props.className || 'actionButton'}
        size={props.size || 25}
        color={props.color}
        title={props.title || ''}
      />
    }
    </>
  )
}

LoadingBootstrapIcon.propTypes = {
  iconName: PropTypes.string,
  smallSpinner: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func
}

export default LoadingBootstrapIcon
